import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import SplashScreen from "../../../../components/SplashScreen";
import * as auth from "../_redux/authRedux";
import * as traineeService from "../../Trainee/_redux/traineeRedux";
import * as assessorService from "../../Assessor/_redux/assessorRedux";

class Logout extends Component {
  componentDidMount() {
    this.props.examLogout();
    this.props.practicalLogout();
    this.props.logout();
  }

  render() {
    const { hasAuthToken } = this.props;
    return hasAuthToken ? <SplashScreen /> : <Redirect to="/auth/login" />;
  }
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  { ...auth.actions, ...traineeService.actions, ...assessorService.actions }
)(Logout);
