import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import * as traineeService from "../app/modules/Trainee/_redux/traineeRedux";
import * as assessorService from "../app/modules/Assessor/_redux/assessorRedux";
//import {productsSlice} from "../app/modules/ECommerce/_redux/products/productsSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  traineeService: traineeService.reducer,
  assessorService: assessorService.reducer,
});

export function* rootSaga() {
  yield all([auth.saga(), traineeService.saga(), assessorService.saga()]);
}
