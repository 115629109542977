export const DEFAULT_INITIAL_EXAM_STATUS = {
  currentQuestion: undefined,
  reviewLaterQuestions: undefined,
  answeredQuestions: undefined,
  unsavedQuestions: undefined,
  questionResponses: undefined,
  isComplete: undefined,
  languageSelected: undefined,
  startTime: undefined,
};

export const DEFAULT_EXAM_STATUS = {
  currentQuestion: 0,
  reviewLaterQuestions: [],
  answeredQuestions: [],
  unsavedQuestions: [],
  questionResponses: [],
  isComplete: false,
  languageSelected: "English",
  startTime: new Date(),
};

export const DEFAULT_EXAM_STATUS_KEY = "tagnet-exam";

export const DEFAULT_INITIAL_PRACTICAL_STATUS = {
  currentQuestion: undefined,
  isComplete: undefined,
  scenarios: undefined,
  practicalQuestionResponses: [],
  trainees: undefined,
  selectedTrainee: undefined,
};

export const DEFAULT_PRACTICAL_STATUS = {
  currentQuestion: 0,
  isComplete: false,
  scenarios: [],
  practicalQuestionResponses: [],
  trainees: [],
  selectedTrainee: "",
};

export const DEFAULT_PRACTICAL_STATUS_KEY = "tagnet-practical";

export const DEFAULT_EXAM_TIMER = 0;

export const DEFAULT_EXAM_TIMER_KEY = "exam-timer";

export const DEFAULT_DATE_TIME_FORMAT = "MM/dd/yyyy HH:mm:ss aaa";

export const DEFAULT_REVIEW_LATER_EVENT = "QuestionReviewLater";

export const DEFAULT_QUESTION_SUBMITTED_EVENT = "QuestionSubmitted";

export const DEFAULT_QUESTION_DISPLAYED_EVENT = "QuestionDisplayed";

export const DEFAULT_LOGIN_EVENT = "Login";

export const DEFAULT_LOGOUT_EVENT = "Logout";

export const DEFAULT_LOCKED_EVENT = "Locked";

export const DEFAULT_AUTOLOGOUT_EVENT = "0";
