/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import AssessorPage from "./AssessorPage";
import TraineePage from "./TraineePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorPage from "./modules/Error/ErrorPage";
import { toAbsoluteUrl } from "../helpers";
import TraineePractical from "./TraineePractical";

export function Routes() {
  const { isAuthorized, isAssessor } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
      isAssessor: auth.userType && auth.userType === "assessor",
    }),
    shallowEqual
  );

  return (
    <>
      <header>
        <div className="logo-small">
          <a href="#_">
            <img
              src={toAbsoluteUrl("/assests/images/logo-small.png")}
              alt="logo"
            />
          </a>
        </div>
      </header>
      <Switch>
        <Route path="/practical" component={TraineePractical} />
        <Route path="/trainee" component={TraineePractical} />
        {!isAuthorized ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <Route>
            <AuthPage />
          </Route>
        ) : (
          /*Otherwise redirect to root page (`/`)*/
          <Redirect from="/auth" to="/" />
        )}

        <Route path="/error" component={ErrorPage} />
        <Route path="/logout" component={Logout} />

        {!isAuthorized ? (
          /*Redirect to `/auth` when user is not authorized*/
          <Redirect to="/auth/login" />
        ) : isAssessor ? (
          <AssessorPage />
        ) : (
          <TraineePage />
        )}
      </Switch>
    </>
  );
}
