import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as auth from "../../Auth/_redux/authRedux";
import * as traineeService from "../_redux/traineeRedux";
import { toAbsoluteUrl } from "../../../../helpers";
import {
  DEFAULT_EXAM_TIMER,
  DEFAULT_EXAM_TIMER_KEY,
} from "../../../../components/Defaults";

function Thanks(props) {
  useEffect(() => {
    localStorage.setItem(
      DEFAULT_EXAM_TIMER_KEY,
      JSON.stringify(DEFAULT_EXAM_TIMER)
    );
    setTimeout(() => {
      props.examLogout();
      props.logout();
    }, 1000);
    // eslint-disable-next-line
  }, []);
  return (
    <div className="mid">
      <div className="login-main">
        <div className="take-photo">
          <img src={toAbsoluteUrl("/assests/images/right.png")} alt="thanks" />
          <h1>Thank You !</h1>
          <h4>Your Exam is Completed.</h4>
        </div>
      </div>
    </div>
  );
}

export default connect(
  ({ auth }) => ({
    user: auth.user,
  }),
  { ...auth.actions, ...traineeService.actions }
)(Thanks);
