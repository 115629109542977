import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import axios from "axios";
import { Button, Row, Col } from "react-bootstrap";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";
import * as auth from "../../Auth/_redux/authRedux";
import * as traineeService from "../_redux/traineeRedux";
import Timer from "../../../../components/Timer";
import { Howl, Howler } from "howler";
import * as htmlToImage from "html-to-image";
import Typingtest from "./Typingtest";
//

import { toast } from "react-toastify";

import { defineTheme } from "../../../lib/defineTheme";
import OutputWindow from "./OutputWindow";
import ThemeDropdown from "./ThemeDropdown";
/* import { customStyles } from "./customStyles";
import LanguagesDropdown from "./LanguagesDropdown"; */
import CodeEditorWindow from "./CodeEditorWindow";
import { classnames } from "../../../utils/general";
import { languageOptions } from "./languageOptions";
//
import {
  DEFAULT_LOGOUT_EVENT,
  DEFAULT_QUESTION_SUBMITTED_EVENT,
  DEFAULT_REVIEW_LATER_EVENT,
} from "../../../../components/Defaults";
import { formatLocalDate } from "../../../../helpers";

function Questions({
  history,
  questions,
  Languages,
  assessmentDuration,
  addExamEvent,
  saveQuestionResponse,
  triggerGenerateResult,
  setCurrentQuestion,
  setQuestionResponse,
  setIsComplete,
  setLanguage,
  setAnsweredQuestions,
  setReviewLaterQuestions,
  SetUnsavedQuestions,
  removeAnsweredQuestions,
  removeReviewLaterQuestions,
  user: { EnrollmentNo, TextSpeech },
  traineeService: {
    currentQuestion,
    startTime,
    reviewLaterQuestions,
    unsavedQuestions,
    answeredQuestions,
    questionResponses,
    languageSelected,
    isComplete,
  },
}) {
  useEffect(() => {
    document.addEventListener(
      "visibilitychange",
      handleVisibilityChange,
      false
    );
    document.addEventListener(
      "contextmenu",
      function (e) {
        e.preventDefault();
      },
      false
    );
    /*  document.addEventListener(
      "keydown",
      function (e) {
        e.preventDefault();
      },
      false
    ); */
    // Removing all event handlers when the page exits
    return function cleanup() {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      document.removeEventListener(
        "contextmenu",
        function (e) {
          e.preventDefault();
        },
        false
      );
      document.removeEventListener(
        "keydown",
        (event) => handleKeyPress(event),
        false
      );
    };
  });

  useEffect(() => {
    defineTheme("oceanic-next").then((_) =>
      setTheme({ value: "oceanic-next", label: "Oceanic Next" })
    );
  }, []);
  ////Coding//////
  const [code, setCode] = useState();
  //const [techlanguage, setTechLanguages] = useState("");
  const [theme, setTheme] = useState("cobalt");
  const [outputDetails, setOutputDetails] = useState(null);
  const [processing, setProcessing] = useState(null);
  ///////////
  const [optionSelected, setOptionSelected] = useState(-1);
  //const [checkResponse, setcheckResponse] = useState(0);
  const checkResponse = useRef(null);
  //const [textinput, setTextInput] = useState("");
  const totalQuestions = questions.length;
  const question = questions[currentQuestion];

  const showQuestion = (qNo) => {
    //alert(inputareaRef.current.value);
    if (checkResponse.current === 1) {
      // alert("Please press Save & Next button to save the response");
      onSaveAndNext();
      //return false;
    }
    /* if (question.langCode !== "") {
      setTechLanguages(question.langCode);
    } */
    setCurrentQuestion(qNo);

    const questionResponse = questionResponses.find((obj) => obj.qNo === qNo);

    setOptionSelected(questionResponse ? questionResponse.response : -1);
  };
  const onOptionSelected = (evt) => {
    var value = evt.target.value;
    //alert(value);
    /* if (value) {
      value = parseInt(value);
    }  */
    setOptionSelected(value);
    checkResponse.current = 1;
    //setTextInput(value);
    // alert(optionSelected);
    setQuestionResponse(currentQuestion, value);
  };

  const onLanguageChange = (evt) => {
    setLanguage(evt.target.value);
  };
  const onComplete = () => {
    setIsComplete(true);
    addExamEvent(
      EnrollmentNo,
      DEFAULT_LOGOUT_EVENT,
      formatLocalDate(new Date()),
      0
    );
    localStorage.removeItem("typingteststatus");
    triggerGenerateResult(EnrollmentNo);
    history.push("/exam/thanks");
  };
  const onReviewLater = () => {
    addExamEvent(
      EnrollmentNo,
      DEFAULT_REVIEW_LATER_EVENT,
      formatLocalDate(new Date()),
      currentQuestion + 1
    );
    removeAnsweredQuestions(currentQuestion);
    setReviewLaterQuestions(currentQuestion);
    if (totalQuestions - 1 === currentQuestion) {
      showQuestion(0);
    } else {
      showQuestion(currentQuestion + 1);
    }
  };
  const onSaveAndNext = async () => {
    checkResponse.current = null;

    /* if(questions[currentQuestion].Type==="Typing")
      {
        if(!localStorage.getItem('typingteststatus'))
        {
          alert("Please Type the paragraph");
        }else{
          setOptionSelected(JSON.parse(localStorage.getItem('typingteststatus'))['input']);    
         
        alert(optionSelected);
        }
         
      } */

    if (
      optionSelected !== -1 ||
      questions[currentQuestion].Type === "Typing" ||
      questions[currentQuestion].Type === "Coding"
    ) {
      let endTime = new Date();
      //alert(questions[currentQuestion].Type);
      let questionId = questions[currentQuestion].Id;
      let resonpses;
      if (questions[currentQuestion].Type === "TextInput") {
        resonpses = optionSelected;
      } else if (questions[currentQuestion].Type === "Typing") {
        if (!localStorage.getItem("typingteststatus")) {
          alert("Please Type the paragraph");
          return false;
        }
        resonpses = JSON.parse(localStorage.getItem("typingteststatus"));
      } else if (questions[currentQuestion].Type === "Coding") {
        resonpses = code;
      } else {
        resonpses = [
          parseInt(optionSelected) === 0 ? "True" : "False",
          parseInt(optionSelected) === 1 ? "True" : "False",
          parseInt(optionSelected) === 2 ? "True" : "False",
          parseInt(optionSelected) === 3 ? "True" : "False",
        ];
      }
      //  alert(resonpses);
      // setcheckResponse(0);
      if (navigator.onLine === false) {
        alert("You are offline");
        if (answeredQuestions.indexOf(currentQuestion) >= 0) {
          removeAnsweredQuestions(currentQuestion);
        }

        SetUnsavedQuestions(currentQuestion);
      } else {
        saveQuestionResponse(
          EnrollmentNo,
          questionId,
          formatLocalDate(startTime),
          formatLocalDate(endTime),
          resonpses
        );
        addExamEvent(
          EnrollmentNo,
          DEFAULT_QUESTION_SUBMITTED_EVENT,
          formatLocalDate(new Date()),
          currentQuestion + 1
        );
        removeReviewLaterQuestions(currentQuestion);
        setAnsweredQuestions(currentQuestion);
      }
      if (totalQuestions - 1 === currentQuestion) {
        showQuestion(0);
      } else {
        showQuestion(currentQuestion + 1);
      }
    } else {
      alert("Please select an option first");
    }
  };
  const onExamEnd = async () => {
    let message =
      "Are you sure you want to end the exam? Please Note: you won't be able change your choice later or retake the exam!";
    let totalAnswered = answeredQuestions.length;
    if (totalAnswered === totalQuestions) {
      if (window.confirm(message)) {
        onComplete();
      }
    } else {
      message =
        "Please make sure to attempt all questions before ending the exam!";
      alert(message);
    }
  };
  //coding
  const handleCompile = () => {
    setProcessing(true);
    const formData = {
      // language_id: language.id,
      language_id: question.langCode,
      // encode source code in base64
      source_code: btoa(code),
      //stdin: btoa(customInput),
    };
    console.log(process.env.REACT_APP_RAPID_API_URL);
    const options = {
      method: "POST",
      url: process.env.REACT_APP_RAPID_API_URL,
      params: { base64_encoded: "true", fields: "*" },
      headers: {
        "content-type": "application/json",
        /* "Content-Type": "application/json",
      "X-RapidAPI-Host": process.env.REACT_APP_RAPID_API_HOST,
       "X-RapidAPI-Key": process.env.REACT_APP_RAPID_API_KEY, */
      },
      data: formData,
    };

    axios
      .request(options)
      .then(function (response) {
        console.log("res.data", response.data);
        const token = response.data.token;

        checkStatus(token);
      })
      .catch((err) => {
        console.log(err);
        // let error = err.response ? err.response.data : err;
        // get error status
        /* let status = err.response.status;
      console.log("status", status);
      if (status === 429) {
        console.log("too many requests", status);

        console.log(
          `Quota of 100 requests exceeded for the Day! Please read the blog on freeCodeCamp to learn how to setup your own RAPID API Judge0!`,
          10000
        );
      } */
        setProcessing(false);
        //console.log("catch block...", error);
      });
  };
  const checkStatus = async (token) => {
    const options = {
      method: "GET",
      url: process.env.REACT_APP_RAPID_API_URL + "/" + token,
      params: { base64_encoded: "true", fields: "*" },
      /* headers: {
        "X-RapidAPI-Host": process.env.REACT_APP_RAPID_API_HOST,
        "X-RapidAPI-Key": process.env.REACT_APP_RAPID_API_KEY,
      }, */
    };
    try {
      let response = await axios.request(options);
      let statusId = response.data.status?.id;

      // Processed - we have a result
      if (statusId === 1 || statusId === 2) {
        // still processing
        setTimeout(() => {
          checkStatus(token);
        }, 2000);
        return;
      } else {
        setProcessing(false);
        setOutputDetails(response.data);
        // showSuccessToast(`Compiled Successfully!`);
        console.log("response.data", response.data);
        return;
      }
    } catch (err) {
      console.log("err", err);
      setProcessing(false);
      showErrorToast();
    }
  };
  /* const showSuccessToast = (msg) => {
    toast.success(msg || `Compiled Successfully!`, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }; */
  const showErrorToast = (msg, timer) => {
    toast.error(msg || `Something went wrong! Please try again.`, {
      position: "top-right",
      autoClose: timer ? timer : 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  /*  const onSelectChange = (sl) => {
    console.log("selected Option...", sl);
   
    setTechLanguages(question.langCode);
  }; */
  function handleThemeChange(th) {
    const theme = th;
    console.log("theme...", theme);

    if (["light", "vs-dark"].includes(theme.value)) {
      setTheme(theme);
    } else {
      defineTheme(theme.value).then((_) => setTheme(theme));
    }
  }
  const onChange = (action, data) => {
    switch (action) {
      case "code": {
        setCode(data);
        checkResponse.current = 1;
        break;
      }
      default: {
        console.warn("case not handled!", action, data);
      }
    }
  };
  //-- end coding function --

  const renderQuestionNav = () => {
    let content = [];
    for (let i = 0; i < questions.length; i++) {
      content.push(
        <li
          key={i}
          onClick={() => {
            showQuestion(i);
          }}
          className={
            currentQuestion === i
              ? "btn btn-primary"
              : reviewLaterQuestions.indexOf(i) >= 0
              ? "btn btn-warning"
              : answeredQuestions.indexOf(i) >= 0
              ? "btn btn-success"
              : unsavedQuestions.indexOf(i) >= 0
              ? "btn btn-danger"
              : "btn btn-secondary"
          }
        >
          <span>{i + 1}</span>
        </li>
      );
    }
    return content;
  };
  const ref = useRef(document.getElementById("takescreenshot"));
  function handleKeyPress(event) {
    if (event.altKey) {
      //setKeyPress(key_press+1);
      swal("Alt Key Press Not Allowed", "", "error");
      return false;
    } else if (event.ctrlKey) {
      // setKeyPress(key_press+1);
      swal("Ctrl Key Press Not Allowed", "", "error");
      return false;
    } else if (event.keyCode === 123) {
      // setKeyPress(key_press+1);
      // swal('F12 Key Press Not Allowed',"", "error");
      return false;
    } else {
      return true;
    }
  }
  async function textToSpeech(ques) {
    // ques = "Kon Banega TAG Saathi me Apka Sawaagat hai Yeh raha apka pahela question "+ ques;
    await axios
      .get(
        `https://kmlneflbo1.execute-api.ap-south-1.amazonaws.com/default/text-to-speech?voice=Aditi&text=` +
          ques
      )
      .then((response) => {
        const src = response.request.responseURL;
        // console.log(response.request.responseURL);
        var sound = new Howl({
          src: [src],
          autoplay: true,
          html5: true,
        });
        // Play the sound.
        sound.play();

        // Change global volume.
        Howler.volume(1.0);
      })
      .catch((err) => {
        console.log("Issue in api Call", err);
      });
  }
  async function handleVisibilityChange() {
    if (document.hidden) {
      // the page is hidden
      //setTabChange(parseInt(1));
      //   console.log(tab_change);
      htmlToImage
        .toPng(ref.current)
        .then(async function (dataUrl) {
          /* var img = new Image()
        img.src = dataUrl */
          const objectName = "tab_changed";
          let capturePhoto = dataUrl.replace("data:image/png;base64,", "");
          const response = await axios
            .post(process.env.REACT_APP_API_URL + "/api/updateobjstatus", {
              EnrollmentNo: EnrollmentNo,
              objectName,
              capturePhoto: capturePhoto,
            })
            .catch((err) => {
              console.log("Issue in api Call", err);
            });

          if (response && response.status === 200) {
            if (response.data.flag === "warning") {
              swal(
                "Exam Warning",
                "Your exam will be terminated any time.",
                "warning"
              );
            }
            if (response.data.flag === "terminated") {
              swal({
                title: "Exam Terminated",
                text: "Your exam has been terminated now",
                icon: "warning",
                dangerMode: true,
              }).then((ok) => {
                if (ok) {
                  console.log("aa gya");
                  swal.close();
                  onComplete();

                  //	return navigate('/report')
                }
              });

              setTimeout(() => {
                swal.close();
                onComplete();
              }, 5000);

              //return redirectpage ? return navigate('/signin') : "";
            }
          } else {
            swal("danger", "Somthing went wrong", "error");
          }
        })
        .catch((error) => {
          console.log("Image not convert", error);
        });

      swal("Changed Tab Detected", "Action has been Recorded", "error");
    } else {
      // the page is visible
      // var node = document.getElementById('takescreenshot');
    }
  }

  return (
    <div style={{ float: "left" }} ref={ref} id="takescreenshot">
      <div className="question-header mb-2">
        <select
          name="language_type"
          style={{ width: 200 }}
          onChange={onLanguageChange}
          value={languageSelected}
        >
          <option value="English">English</option>
          {Languages.map((langoptions, indx) => {
            return (
              <option key={indx} value={langoptions.langCode}>
                {langoptions.language}
              </option>
            );
          })}
        </select>
        <div className="time">
          <p>
            <Timer
              assessmentDuration={assessmentDuration}
              onComplete={onComplete}
            />
          </p>
        </div>
      </div>

      <div className="question-div">
        <div className="question-div-left">
          <div className="question-header">
            <h3>Test Monitor</h3>
          </div>
          <div className="question-div-left-bottom">
            <Row>
              <Col sm={12} md={3}>
                <p className="bg-primary text-white p-2 text-center">
                  Current Question
                </p>
              </Col>
              <Col sm={12} md={3}>
                <p className="bg-success text-white p-2 text-center">
                  Answered
                </p>
              </Col>
              {/* <li>
                <span className="bg-secondary" />
                <p>Not Answered</p>
              </li> */}
              <Col sm={12} md={3}>
                <p className="bg-warning p-2 text-center">Review Later</p>
              </Col>
              <Col sm={12} md={3}>
                <p className="bg-danger text-white p-2 text-center">Unsaved</p>
              </Col>
            </Row>
            <div className="question-no">
              <ul>{renderQuestionNav()}</ul>
            </div>
          </div>
        </div>
        <div className="question-div-right" id="question-div-right-html">
          <div className="question-header">
            <h3>
              Directions: Select the related word/letter/ number from the given
              alternatives.
            </h3>
          </div>
          <div className="question-div-right-bottom">
            <h3>
              {currentQuestion + 1}
              {". "}
              <span>
                {question.Type === "Text" ||
                question.Type === "TextInput" ||
                question.Type === "Typing" ? (
                  languageSelected === "English" ? (
                    question.Type === "Typing" ? (
                      "Type the following paragraph into text box. (Paragraph is case sensitive.) "
                    ) : (
                      question.Text
                    )
                  ) : (
                    //console.log(question.Texts[0][languageSelected])
                    question.Texts[0][languageSelected]
                  )
                ) : question.Type === "TextImage" ||
                  question.Type === "TextVideo" ||
                  question.Type === "TextAudio" ||
                  question.Type === "Coding" ? (
                  <>
                    <>
                      {languageSelected === "English"
                        ? question.Text
                        : question.Texts[0][languageSelected]}
                    </>{" "}
                    {question.Type === "TextVideo" ? (
                      <ReactPlayer
                        className="react-player"
                        controls
                        config={{
                          file: { attributes: { controlsList: "nodownload" } },
                        }}
                        url={question.Image}
                        width="300px"
                        height="250px"
                      />
                    ) : question.Type === "TextAudio" ? (
                      <ReactAudioPlayer
                        src={question.Image}
                        controls
                        controlsList="nodownload"
                      />
                    ) : question.Image !== "" ? (
                      <img
                        src={question.Image}
                        alt={currentQuestion}
                        width={150}
                      />
                    ) : (
                      ""
                    )}
                  </>
                ) : question.Type === "Video" ? (
                  <ReactPlayer
                    className="react-player"
                    controls
                    config={{
                      file: { attributes: { controlsList: "nodownload" } },
                    }}
                    playIcon
                    url={question.Image}
                    playicon
                    width="300px"
                    height="250px"
                  />
                ) : question.Type === "Audio" ? (
                  <ReactAudioPlayer
                    src={question.Image}
                    controls
                    controlsList="nodownload"
                  />
                ) : (
                  <img src={question.Image} alt={currentQuestion} width={150} />
                )}
                {TextSpeech === "1" ? (
                  <Button
                    id="playsound"
                    style={{ marginLeft: "5px" }}
                    onClick={() => {
                      languageSelected === "English"
                        ? textToSpeech(question.Text)
                        : textToSpeech(question.Texts[0][languageSelected]);
                    }}
                  >
                    <i className="fa fa-volume-up" aria-hidden="true"></i>
                  </Button>
                ) : null}
              </span>
            </h3>

            {question.Type === "TextInput" ? (
              <textarea
                className="input_area"
                style={{ height: 150 }}
                value={optionSelected === -1 ? "" : optionSelected}
                onChange={onOptionSelected}
              />
            ) : question.Type === "Typing" ? (
              <Typingtest text={question.Text} />
            ) : question.Type === "Coding" ? (
              <>
                <div
                  style={{ minHeight: "70vh" }}
                  className="flex flex-row space-x-4 items-start px-4 py-4"
                >
                  <div
                    className="flex flex-col w-full h-full justify-start items-end row"
                    style={{ width: "100%" }}
                  >
                    <div className="px-4 py-2 col-md-4">
                      {/*  <LanguagesDropdown
                        defaultValue={techlanguage}
                        onSelectChange={onSelectChange}
                      /> */}
                      <span
                        style={{
                          border: "2px solid black",
                          float: "left",
                          width: "100%",
                        }}
                        className={classnames(
                          "mt-1 border-2 compilebutton border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0"
                        )}
                      >
                        {languageOptions.map((langCode, index) => {
                          if (langCode.id === question.langCode) {
                            return langCode.name;
                          }
                        })}
                      </span>
                    </div>
                    <div className="px-4 py-2 col-md-4">
                      <ThemeDropdown
                        handleThemeChange={handleThemeChange}
                        theme={theme}
                      />
                    </div>
                    <div className="px-4 py-2 col-md-4">
                      <button
                        style={{ border: "2px solid black" }}
                        onClick={handleCompile}
                        disabled={!code}
                        className={classnames(
                          "mt-1 border-2 compilebutton border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0",
                          !code ? "opacity-50" : ""
                        )}
                      >
                        {processing ? "Processing..." : "Compile and Execute"}
                      </button>
                    </div>
                  </div>
                  <div
                    className="right-container flex flex-shrink-0 w-[30%] flex-col"
                    style={{ marginTop: "2%" }}
                  >
                    <CodeEditorWindow
                      code={code}
                      onChange={onChange}
                      language={
                        languageOptions.find((ele) => {
                          return ele.id === question.langCode;
                        })?.value
                      }
                      theme={theme.value}
                    />
                    {console.log(
                      languageOptions.find((ele) => {
                        return ele.id === question.langCode;
                      })
                    )}
                    {/* <ToastContainer
                      position="top-right"
                      autoClose={2000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                    /> */}

                    <OutputWindow outputDetails={outputDetails} />
                  </div>
                </div>
              </>
            ) : question.Options ? (
              question.Options.map((option, indx) => {
                return (
                  <div className="ans-option" key={indx}>
                    <label className="option">
                      {indx + 1}
                      {") "}
                      <span>
                        {option.Type === "Text" ? (
                          languageSelected === "English" ? (
                            option.Text
                          ) : (
                            option.Texts[0][languageSelected]
                          )
                        ) : option.Type === "TextImage" ? (
                          <>
                            <>
                              {languageSelected === "English"
                                ? option.Text
                                : option.Texts[0][languageSelected]}
                            </>{" "}
                            <img src={option.Image} alt={indx} width={150} />
                          </>
                        ) : (
                          <img src={option.Image} alt={indx} width={150} />
                        )}
                        {TextSpeech === "1" ? (
                          <i
                            aria-hidden="true"
                            className="fa fa-volume-up"
                            style={{ marginLeft: "5px" }}
                            onClick={() => {
                              languageSelected === "English"
                                ? textToSpeech(option.Text)
                                : textToSpeech(
                                    option.Texts[0][languageSelected]
                                  );
                            }}
                          ></i>
                        ) : null}
                      </span>
                      <input
                        type="radio"
                        name="optionSelected"
                        value={indx}
                        checked={parseInt(optionSelected) === parseInt(indx)}
                        onChange={onOptionSelected}
                      />
                      <span className="checkmark">
                        <i className="fa fa-check"></i>
                      </span>
                    </label>
                  </div>
                );
              })
            ) : (
              ""
            )}

            {totalQuestions === answeredQuestions.length ? (
              <Button variant="danger" className="mr-2" onClick={onExamEnd}>
                End Exam
              </Button>
            ) : (
              <Button variant="warning" onClick={onReviewLater}>
                Review Later
              </Button>
            )}
            <Button
              variant="primary"
              className="pull-right"
              onClick={onSaveAndNext}
            >
              Save & Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(
  ({ auth, traineeService }) => ({
    questions: auth.questions,
    Languages: auth.Languages,
    assessmentDuration: auth.assessmentDuration,
    user: auth.user,
    traineeService: traineeService,
  }),
  { ...auth.actions, ...traineeService.actions }
)(Questions);
