/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import Login from "./Login";

export function PracticalAuthPage() {
  return (
    <>
      <Switch>
        <Route path="/practical/login" component={Login} />
        <Redirect from="/practical" exact={true} to="/practical/login" />
        <Redirect to="/practical/login" />
      </Switch>
    </>
  );
}
