import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import {
  DEFAULT_PRACTICAL_STATUS,
  DEFAULT_PRACTICAL_STATUS_KEY,
} from "../../../../components/Defaults";
import { triggerGenerateResult } from "../../Trainee/_redux/traineeCrud";

import {
  getPracticalScenarios,
  savePracticalQuestionResponse,
  saveOjtResponse,
} from "./assessorCrud";

const initialAuthState = DEFAULT_PRACTICAL_STATUS;

export const actionTypes = {
  PracticalLogout: "[Practical Logout] Action",
  GetPracticalScenarios: "[Get Practical Scenarios] Action",
  SetPracticalQuestionResponse: "[Set Practical Question Response] Action",
  savePracticalQuestionResponse: "[Save  Practical Question Response] Action",
  triggerGenerateResult: "[Trigger Practical Generate Result] Action",
  PracticalScenariosLoaded: "[Practical Scenarios Loaded] Action",
  SelectTrainee: "[Select Trainee] Action",
  saveOjtResponse: "[Save OJT Marks] Action",
};

export const reducer = persistReducer(
  {
    storage,
    key: DEFAULT_PRACTICAL_STATUS_KEY,
    whitelist: [
      "currentQuestion",
      "isComplete",
      "scenarios",
      "trainees",
      "practicalQuestionResponses",
      "selectedTrainee",
      "Ojt",
    ],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.PracticalLogout: {
        return DEFAULT_PRACTICAL_STATUS;
      }
      case actionTypes.GetPracticalScenarios: {
        return state;
      }
      case actionTypes.PracticalScenariosLoaded: {
        const {
          PracticalScenarios: scenarios,
          Trainees: trainees,
          Ojt,
        } = action.payload;
        return { ...state, scenarios, trainees, Ojt };
      }
      case actionTypes.triggerGenerateResult: {
        return state;
      }
      case actionTypes.SelectTrainee: {
        const selectedTrainee = action.payload;
        return { ...state, selectedTrainee };
      }
      case actionTypes.savePracticalQuestionResponse: {
        return state;
      }
      case actionTypes.saveOjtResponse: {
        return state;
      }
      case actionTypes.SetPracticalQuestionResponse: {
        const { questionNo, selectedValue } = action.payload;

        let { practicalQuestionResponses } = state;
        let qIndx = practicalQuestionResponses.findIndex(
          (obj) => obj.QuestionId === questionNo
        );

        if (qIndx >= 0) {
          let qRes = [...practicalQuestionResponses];

          let questionResponse = { ...qRes[qIndx] };
          //  console.log(questionResponse);
          questionResponse.Response = selectedValue;
          practicalQuestionResponses[qIndx] = questionResponse;
          qRes[qIndx] = questionResponse;
          return { ...state, practicalQuestionResponses: qRes };
        } else {
          let practicalQuestionResponse = {
            QuestionId: questionNo,
            Response: selectedValue,
          };

          return {
            ...state,
            practicalQuestionResponses: [
              ...state.practicalQuestionResponses,
              practicalQuestionResponse,
            ],
          };
        }
      }
      /* case actionTypes.SetQuestionResponse: {
        const { questionNo, selectedValue } = action.payload;
        let { questionResponses } = state;
        let qIndx = questionResponses.findIndex(
          (obj) => obj.qNo === questionNo
        );
        if (qIndx >= 0) {
          let qRes = [...questionResponses];
          let questionResponse = { ...qRes[qIndx] };
          questionResponse.response = selectedValue;
          questionResponses[qIndx] = questionResponse;
          qRes[qIndx] = questionResponse;
          return { ...state, questionResponses: qRes };
        } else {
          let questionResponse = {
            qNo: questionNo,
            response: selectedValue,
          };
          return {
            ...state,
            questionResponses: [...state.questionResponses, questionResponse],
          };
        }
      } */
      default:
        return state;
    }
  }
);
export const actions = {
  practicalLogout: () => ({ type: actionTypes.PracticalLogout }),
  loadPracticalScenarios: () => ({ type: actionTypes.GetPracticalScenarios }),
  fulfillPracticalScenarios: (scenarioData) => {
    return {
      type: actionTypes.PracticalScenariosLoaded,
      payload: scenarioData,
    };
  },
  triggerGenerateResult: (enrollmentNo) => ({
    type: actionTypes.triggerGenerateResult,
    payload: enrollmentNo,
  }),
  selectTrainee: (selectedTrainee) => ({
    type: actionTypes.SelectTrainee,
    payload: selectedTrainee,
  }),
  savePracticalQuestionResponse: (enrollmentNo, responses) => ({
    type: actionTypes.savePracticalQuestionResponse,
    payload: { enrollmentNo, responses },
  }),
  saveOjtResponse: (enrollmentNo, responses) => ({
    type: actionTypes.saveOjtResponse,
    payload: { enrollmentNo, responses },
  }),
  setPracticalQuestionResponse: (questionNo, selectedValue) => ({
    type: actionTypes.SetPracticalQuestionResponse,
    payload: { questionNo, selectedValue },
  }),
};
export function* saga() {
  yield takeLatest(
    actionTypes.GetPracticalScenarios,
    function* getPracticalScenariosSaga(actionData) {
      const {
        data: { Data },
      } = yield getPracticalScenarios();
      yield put(actions.fulfillPracticalScenarios(Data));
    }
  );
  yield takeLatest(
    actionTypes.triggerGenerateResult,
    function* triggerGenerateResultSaga({ payload: enrollmentNo }) {
      yield triggerGenerateResult(enrollmentNo);
    }
  );

  yield takeLatest(
    actionTypes.savePracticalQuestionResponse,
    function* savePracticalQuestionResponseSaga({
      payload: { enrollmentNo, responses },
    }) {
      yield savePracticalQuestionResponse(enrollmentNo, responses);
    }
  );
  yield takeLatest(
    actionTypes.saveOjtResponse,
    function* saveOjtResponseSaga({ payload: { enrollmentNo, responses } }) {
      yield saveOjtResponse(enrollmentNo, responses);
    }
  );
}
