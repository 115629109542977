/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import axios from "axios";

import { Switch, Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import { OpenVidu } from "openvidu-browser";
import ReactInterval from "react-interval";
import * as auth from "../../Auth/_redux/authRedux";
import Instructions from "./Instructions";
import Questions from "./Questions";
import Thanks from "./Thanks";
import "react-toastify/dist/ReactToastify.css";
import * as cocoSsd from "@tensorflow-models/coco-ssd";
import { ToastContainer, toast } from "react-toastify";
import "@tensorflow/tfjs";

import Detection from "./Detection";
//import Posenet from './Pose_Detection';
import { getToken } from "../../../../components/OpenViduSession";
import { DEFAULT_LOGOUT_EVENT } from "../../../../components/Defaults";
import { formatLocalDate } from "../../../../helpers";
import * as traineeService from "../_redux/traineeRedux";

function ExamPage(props) {
  const {
    user,
    captureInterval,
    triggerGenerateResult,
    addExamEvent,
    history,
  } = props;
  /*  const sessId = user
    ? `session_theory_${user.BatchId}_${user.EnrollmentNo}`
    : ``; */
  const sessId = user ? `session_theory_${user.BatchId}` : ``;
  //console.log(props.location.pathname);
  // const [capturedImage, setCapturedImage] = useState("");
  // const [hasImage, setHasImage] = useState(false);
  // const [tab_change, setTabChange] = useState(0);
  const constraints = {
    audio: false,
    video: { width: 320, height: 240 },
  };

  useEffect(() => {
    // document.addEventListener("visibilitychange", handleVisibilityChange, false);

    if (user.ObjectDetection === "1") {
      modelPromiseLoad();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.ObjectDetection]);

  useEffect(() => {
    // document.addEventListener("visibilitychange", handleVisibilityChange, false);
    joinSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [modelPromise, setModelPromise] = useState("");
  const modelPromiseLoad = async () => {
    const modelload = await toast.promise(
      cocoSsd.load(),
      document.getElementById("acceptbtn") !== null
        ? {
            pending:
              ((document.getElementById("acceptbtn").disabled = true),
              "please wait"),
            success:
              ((document.getElementById("acceptbtn").disabled = false),
              "Exam Started now"),
            error: "Promise rejected 🤯",
          }
        : {
            pending: "please wait",
            success: "Exam Started now",
            error: "Promise rejected 🤯",
          }
    );
    setModelPromise(modelload);
  };

  // eslint-disable-next-line
  const joinSession = () => {
    console.log(user.EnrollmentNo);
    if (user && sessId && user.EnrollmentNo) {
      getToken(sessId)
        .then((token) => {
          let OV = new OpenVidu();
          OV.enableProdMode();
          let ov_session = OV.initSession();
          ov_session
            .connect(token, { clientData: user.EnrollmentNo })
            .then(() => {
              OV.getDevices().then((devices) => {
                var videoDevices = devices.filter(
                  (device) => device.kind === "videoinput"
                );
                var videoSource = undefined;
                if (videoDevices && videoDevices.length > 1) {
                  // // In mobile devices the default and first camera is the front one
                  videoSource = videoDevices[0].deviceId;
                } else {
                }
                let user_video = OV.initPublisher(undefined, {
                  audioSource: undefined, // The source of audio. If undefined default microphone
                  videoSource: videoSource, // The source of video. If undefined default webcam
                  publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
                  publishVideo: true, // Whether you want to start publishing with your video enabled or not
                  resolution: "640x480", // The resolution of your video
                  frameRate: 30, // The frame rate of your video
                  insertMode: "APPEND", // How the video is inserted in the target element 'video-container'
                  mirror: false, // Whether to mirror your local video or not
                });

                ov_session.publish(user_video);
              });
            })
            .catch((error) => {
              console.log(ov_session);
              console.log(
                "There was an error connecting to the session:",
                error.code,
                error.message
              );
            });
        })
        .catch((Err) => console.error(Err));
      getToken(sessId)
        .then((token) => {
          let OV = new OpenVidu();
          let ov_session = OV.initSession();
          ov_session
            .connect(token, { clientData: user.EnrollmentNo })
            .then(() => {
              // // let user_screen = OV.initPublisher(undefined, {
              // //   audioSource: null,
              // //   videoSource: "screen",
              // //   publishAudio: false,
              // //   publishVideo: true,
              // //   resolution: "640x480",
              // //   frameRate: 30,
              // //   insertMode: "APPEND",
              // //   mirror: false,
              // // });
              // // ov_session.publish(user_screen);
            })
            .catch((error) => {
              console.log(
                "There was an error connecting to the session:",
                error.code,
                error.message
              );
            });
        })
        .catch((Err) => console.error(Err));
    }
  };

  const takePicture = () => {
    getUserMedia(constraints)
      .then((stream) => {
        const video = document.querySelector("video");
        video.srcObject = stream;
        video.play();
      })
      .catch((err) => {
        console.log(err);
      });
    //  let video = detection_videoRef.current
    const canvas = document.querySelector("canvas");
    const context = canvas.getContext("2d");
    const video = document.querySelector("video");
    const { width, height } = constraints.video;
    canvas.width = width;
    canvas.height = height;
    context.drawImage(video, 0, 0, width, height);
    // context.drawImage(video, 0, 0, video.width, video.height);
    // console.log(video.width, video.height);
    const data = canvas.toDataURL("image/png");
    // // TODO: post image to api
    uploadPicture(data);

    clearPhoto();
  };

  const clearPhoto = () => {
    const canvas = document.querySelector("canvas");
    const context = canvas.getContext("2d");
    const { width, height } = constraints.video;
    context.fillStyle = "#FFF";
    context.fillRect(0, 0, width, height);
  };
  /* const closeOpenviduSession = () =>{

  } */
  const uploadPicture = async (capturedImage) => {
    const UPLOAD_URL = `${process.env.REACT_APP_API_URL}/api/traineeimage`;
    try {
      await axios
        .post(UPLOAD_URL, {
          EnrollmentNo: user.EnrollmentNo,
          AssessmentImage: capturedImage
            ? capturedImage.replace("data:image/png;base64,", "")
            : "",
        })
        .then(function (response) {
          if (response.data.AutoLogoutStatus === "1") {
            addExamEvent(
              user.EnrollmentNo,
              DEFAULT_LOGOUT_EVENT,
              formatLocalDate(new Date()),
              0
            );
            localStorage.removeItem("typingteststatus");
            triggerGenerateResult(user.EnrollmentNo);
            history.push("/exam/thanks");
          }
        });
    } catch (err) {}
  };
  const getUserMedia = (params) =>
    new Promise((successCallback, errorCallback) => {
      navigator.webkitGetUserMedia.call(
        navigator,
        params,
        successCallback,
        errorCallback
      );
    });
  const styles = {
    picSize: {
      width: 320,
      height: 240,
    },
  };
  console.log(modelPromise);
  return (
    <>
      <canvas id="canvas" style={styles.picSize} hidden></canvas>
      <video
        autoPlay="autoplay"
        style={styles.picSize}
        className="mt-1"
        hidden
      />
      {props.location.pathname === "/exam/questions" ? (
        <>
          <Detection modelPromise={modelPromise} />
          <ReactInterval
            timeout={1000 * captureInterval}
            enabled={true}
            callback={() => takePicture()}
          />
        </>
      ) : null}
      <ToastContainer autoClose={1500} hideProgressBar />
      <Switch>
        <Route path="/exam/instructions" component={Instructions} />
        <Route path="/exam/questions" component={Questions} />
        {/* <Route path="/exam/questions" render={(props) => <Questions {...props} alert={alert} />} /> */}
        <Route path="/exam/thanks" component={Thanks} />
        <Redirect from="/exam" exact={true} to="/exam/instructions" />
        <Redirect to="/auth/login" />
      </Switch>
    </>
  );
}

export default connect(
  ({ auth }) => ({
    user: auth.user,
    captureInterval: auth.captureInterval,
    addExamEvent: auth.addExamEvent,
    triggerGenerateResult: auth.triggerGenerateResult,
  }),
  { ...auth.actions, ...traineeService.actions }
)(ExamPage);
