import React from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import * as auth from "../../Auth/_redux/authRedux";
import * as traineeService from "../_redux/traineeRedux";

function Instructions({ terms, history, examStart }) {
  return (
    <>
      <div className="mid">
        <div className="rule-main">
          <h2>exam rules and regulations</h2>
          {terms ? (
            <ul>
              {terms.map((str, idx) => {
                return (
                  <li key={idx}>{str.replace("•", "").replace("\t", "")}</li>
                );
              })}
            </ul>
          ) : (
            ""
          )}
          <div>
            <Button
              id="acceptbtn"
              style={{ float: "right" }}
              onClick={() => {
                examStart();
                history.push("/exam/questions");
              }}
            >
              I Accept
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(
  ({ auth }) => ({
    terms: auth.terms,
  }),
  { ...auth.actions, ...traineeService.actions }
)(Instructions);
