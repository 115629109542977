import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import * as traineeService from "../../Trainee/_redux/traineeRedux";
// import { toAbsoluteUrl } from "../../../../helpers";
import "./login.scss";
import { DEFAULT_LOGIN_EVENT } from "../../../../components/Defaults";
import { formatLocalDate } from "../../../../helpers";
import { useLocation } from "react-router-dom";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  username: "",
  password: "",
};

function Login(props) {
  const { logout } = props;
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const LoginSchema = Yup.object().shape({
    username: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(`Username is required`),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(`Password is required`),
  });

  useEffect(() => {
    logout();
    // eslint-disable-next-line
  }, []);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.username, values.password)
          .then(({ data: { Data, StatusCode, Desc } }) => {
            if (StatusCode === 200) {
              disableLoading();
              props.login(Data);
              if (Data && Data.UserType === "trainee") {
                const currentUrl = location.pathname;
                if (currentUrl === "/auth/login") {
                  props.addExamEvent(
                    Data.Trainee.EnrollmentNo,
                    DEFAULT_LOGIN_EVENT,
                    formatLocalDate(new Date()),
                    0
                  );
                }
              }
            } else if (StatusCode === 201) {
              const currentUrl = location.pathname;
              if (currentUrl === "/auth/login") {
                disableLoading();
                setSubmitting(false);
                setStatus(Desc);
              }
              if (currentUrl === "/practical/login") {
                disableLoading();
                props.login(Data);
              }
            } else {
              disableLoading();
              setSubmitting(false);
              setStatus(
                "Login failed! Please check credentials and try again."
              );
            }
          })
          .catch(() => {
            disableLoading();
            setSubmitting(false);
            setStatus("Login failed! Please check credentials and try again.");
          });
      }, 1000);
    },
  });

  return (
    <>
      <div className="mid">
        <div className="login-main">
          {/* <div className="logo">
            <a href="#_">
              <img src={toAbsoluteUrl("/assests/images/logo.png")} alt="logo" />
            </a>
          </div> */}
          <div
            className="login-form login-signin login-div"
            id="login_signin_form"
          >
            {/*begin::Form*/}
            <form
              onSubmit={formik.handleSubmit}
              className="form fv-plugins-bootstrap fv-plugins-framework"
            >
              {formik.status ? (
                <div className="mb-10 alert text-danger">
                  <div className="alert-text font-weight-bold">
                    {formik.status}
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="form-group fv-plugins-icon-container">
                <input
                  placeholder="Username"
                  type="username"
                  className={`form-control form-control-solid h-auto ${getInputClasses(
                    "username"
                  )}`}
                  name="username"
                  autoComplete="username"
                  {...formik.getFieldProps("username")}
                />
                {formik.touched.username && formik.errors.username ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.username}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="form-group fv-plugins-icon-container">
                <input
                  placeholder="Password"
                  type="password"
                  className={`form-control form-control-solid h-auto ${getInputClasses(
                    "password"
                  )}`}
                  name="password"
                  autoComplete="current-password"
                  {...formik.getFieldProps("password")}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.password}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                <button
                  id="btnLogin"
                  type="submit"
                  disabled={formik.isSubmitting}
                  className={`btn btn-primary font-weight-bold`}
                >
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      style={{ margin: "5px" }}
                    ></span>
                  )}
                  <span>Sign In</span>
                </button>
              </div>
            </form>
            {/*end::Form*/}
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(null, { ...auth.actions, ...traineeService.actions })(
  Login
);
