import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest } from "redux-saga/effects";
import {
  DEFAULT_EXAM_STATUS,
  DEFAULT_EXAM_STATUS_KEY,
  DEFAULT_INITIAL_EXAM_STATUS,
} from "../../../../components/Defaults";

import {
  addExamEvent,
  saveQuestionResponse,
  triggerGenerateResult,
} from "./traineeCrud";

const initialAuthState = DEFAULT_EXAM_STATUS;

export const actionTypes = {
  ExamStart: "[Exam Start] Action",
  ExamLogout: "[Exam Logout] Action",
  AddExamEvent: "[Add Exam Event] Action",
  SaveQuestionResponse: "[Save Question Response] Action",
  TriggerGenerateResult: "[Trigger Generate Result] Action",
  SetCurrentQuestion: "[Set Current Question] Action",
  SetQuestionResponse: "[Set Question Response] Action",
  SetIsComplete: "[Set Is Complete] Action",
  SetLanguage: "[Set Language] Action",
  SetAnsweredQuestions: "[Set Answered Questions] Action",
  SetReviewLaterQuestions: "[Set Review Later Questions] Action",
  SetUnsavedQuestions: "[Set Unsaved Questions] Action",
  RemoveAnsweredQuestions: "[Remove Answered Questions] Action",
  RemoveReviewLaterQuestions: "[Remove Review Later Questions] Action",
  AutoLogoutByAdmin: "[Auto Logout By Admin] Action",
};

export const reducer = persistReducer(
  {
    storage,
    key: DEFAULT_EXAM_STATUS_KEY,
    whitelist: [
      "currentQuestion",
      "reviewLaterQuestions",
      "unsavedQuestions",
      "answeredQuestions",
      "questionResponses",
      "isComplete",
      "languageSelected",
      "startTime",
    ],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.ExamStart: {
        return initialAuthState;
      }
      case actionTypes.ExamLogout: {
        return DEFAULT_INITIAL_EXAM_STATUS;
      }
      case actionTypes.AddExamEvent: {
        return state;
      }
      case actionTypes.SaveQuestionResponse: {
        return state;
      }
      case actionTypes.TriggerGenerateResult: {
        return state;
      }
      case actionTypes.AutoLogoutByAdmin: {
        return state;
      }
      case actionTypes.SetCurrentQuestion: {
        const { questionNo } = action.payload;
        let startTime = new Date();
        let currentQuestion = questionNo;
        return { ...state, currentQuestion, startTime };
      }
      case actionTypes.SetQuestionResponse: {
        const { questionNo, selectedValue } = action.payload;
        let { questionResponses } = state;
        let qIndx = questionResponses.findIndex(
          (obj) => obj.qNo === questionNo
        );
        if (qIndx >= 0) {
          let qRes = [...questionResponses];
          let questionResponse = { ...qRes[qIndx] };
          questionResponse.response = selectedValue;
          questionResponses[qIndx] = questionResponse;
          qRes[qIndx] = questionResponse;
          return { ...state, questionResponses: qRes };
        } else {
          let questionResponse = {
            qNo: questionNo,
            response: selectedValue,
          };
          return {
            ...state,
            questionResponses: [...state.questionResponses, questionResponse],
          };
        }
      }
      case actionTypes.SetIsComplete: {
        const { isComplete } = action.payload;
        return { ...state, isComplete };
      }
      case actionTypes.SetLanguage: {
        const { languageSelected } = action.payload;
        return { ...state, languageSelected };
      }
      case actionTypes.SetAnsweredQuestions: {
        const { questionNo } = action.payload;
        const { answeredQuestions } = state;
        if (answeredQuestions.indexOf(questionNo) === -1) {
          // // answeredQuestions.push(questionNo);
          return {
            ...state,
            answeredQuestions: [...state.answeredQuestions, questionNo],
          };
        } else {
          return state;
        }
      }
      case actionTypes.SetReviewLaterQuestions: {
        const { questionNo } = action.payload;
        const { reviewLaterQuestions } = state;
        if (reviewLaterQuestions.indexOf(questionNo) === -1) {
          // // reviewLaterQuestions.push(questionNo);
          return {
            ...state,
            reviewLaterQuestions: [...state.reviewLaterQuestions, questionNo],
          };
        } else {
          return state;
        }
      }
      case actionTypes.SetUnsavedQuestions: {
        const { questionNo } = action.payload;
        const { unsavedQuestions } = state;
        if (unsavedQuestions.indexOf(questionNo) === -1) {
          // // reviewLaterQuestions.push(questionNo);
          return {
            ...state,
            unsavedQuestions: [...state.unsavedQuestions, questionNo],
          };
        } else {
          return state;
        }
      }
      case actionTypes.RemoveAnsweredQuestions: {
        const { questionNo } = action.payload;
        const { answeredQuestions } = state;
        let aqIndx = answeredQuestions.indexOf(questionNo);
        // // console.log("🚀 ~ file: traineeRedux.js ~ line 127 ~ aqIndx", aqIndx);
        if (aqIndx !== -1) {
          // // answeredQuestions.splice(aqIndx, 1);
          return {
            ...state,
            answeredQuestions: state.answeredQuestions.filter((aq) => {
              return aq !== questionNo;
            }),
          };
        } else {
          return state;
        }
      }
      case actionTypes.RemoveReviewLaterQuestions: {
        const { questionNo } = action.payload;
        const { reviewLaterQuestions } = state;
        let rlIndx = reviewLaterQuestions.indexOf(questionNo);
        // // console.log("🚀 ~ file: traineeRedux.js ~ line 141 ~ rlIndx", rlIndx);
        if (rlIndx !== -1) {
          // // reviewLaterQuestions.splice(aqIndx, 1);
          return {
            ...state,
            reviewLaterQuestions: state.reviewLaterQuestions.filter((rl) => {
              return rl !== questionNo;
            }),
          };
        } else {
          return state;
        }
      }
      default:
        return state;
    }
  }
);

export const actions = {
  examStart: () => ({ type: actionTypes.ExamStart }),
  examLogout: () => ({ type: actionTypes.ExamLogout }),
  autoLogout: () => ({ type: actionTypes.AutoLogoutByAdmin }),
  addExamEvent: (enrollmentNo, type, time, questionNo) => ({
    type: actionTypes.AddExamEvent,
    payload: { enrollmentNo, type, time, questionNo },
  }),
  saveQuestionResponse: (
    enrollmentNo,
    questionId,
    startTime,
    endTime,
    responses
  ) => ({
    type: actionTypes.SaveQuestionResponse,
    payload: { enrollmentNo, questionId, startTime, endTime, responses },
  }),
  triggerGenerateResult: (enrollmentNo) => ({
    type: actionTypes.TriggerGenerateResult,
    payload: { enrollmentNo },
  }),
  setCurrentQuestion: (questionNo) => ({
    type: actionTypes.SetCurrentQuestion,
    payload: { questionNo },
  }),
  setQuestionResponse: (questionNo, selectedValue) => ({
    type: actionTypes.SetQuestionResponse,
    payload: { questionNo, selectedValue },
  }),
  setIsComplete: (isComplete) => ({
    type: actionTypes.SetIsComplete,
    payload: { isComplete },
  }),
  setLanguage: (languageSelected) => ({
    type: actionTypes.SetLanguage,
    payload: { languageSelected },
  }),
  setAnsweredQuestions: (questionNo) => ({
    type: actionTypes.SetAnsweredQuestions,
    payload: { questionNo },
  }),
  setReviewLaterQuestions: (questionNo) => ({
    type: actionTypes.SetReviewLaterQuestions,
    payload: { questionNo },
  }),
  SetUnsavedQuestions: (questionNo) => ({
    type: actionTypes.SetUnsavedQuestions,
    payload: { questionNo },
  }),
  removeAnsweredQuestions: (questionNo) => ({
    type: actionTypes.RemoveAnsweredQuestions,
    payload: { questionNo },
  }),
  removeReviewLaterQuestions: (questionNo) => ({
    type: actionTypes.RemoveReviewLaterQuestions,
    payload: { questionNo },
  }),
};

export function* saga() {
  yield takeLatest(
    actionTypes.AddExamEvent,
    function* addExamEventSaga({
      payload: { enrollmentNo, type, time, questionNo },
    }) {
      yield addExamEvent(enrollmentNo, type, time, questionNo);
    }
  );
  yield takeLatest(
    actionTypes.SaveQuestionResponse,
    function* saveQuestionResponseSaga({
      payload: { enrollmentNo, questionId, startTime, endTime, responses },
    }) {
      yield saveQuestionResponse(
        enrollmentNo,
        questionId,
        startTime,
        endTime,
        responses
      );
    }
  );
  yield takeLatest(
    actionTypes.TriggerGenerateResult,
    function* triggerGenerateResultSaga({ payload: { enrollmentNo } }) {
      yield triggerGenerateResult(enrollmentNo);
    }
  );
}
