import React, { Component } from "react";
//import ReactPlayer from "react-player";
import "./UserVideo.css";

class OpenViduVideo extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.mutedStatus = React.createRef();
  }

  componentDidUpdate(props) {
    if (props && !!this.videoRef) {
      this.props.streamManager.addVideoElement(this.videoRef.current);
    }
  }

  componentDidMount() {
    if (this.props && !!this.videoRef) {
      this.props.streamManager.addVideoElement(this.videoRef.current);
    }
    //  this.mutedStatus.current = false;
  }

  render() {
    // console.log(this.props.mutedStatus);
    /* return <ReactPlayer className="react-player" ref={this.videoRef} />; */
    //console.log();
    return (
      <video muted={this.props.muted} autoPlay={true} ref={this.videoRef} />
    );
  }
}

export default class UserVideoComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPlaying: true, // Initially set to false
    };
    // console.log(this.props.mutedStatus.current);
  }
  getNicknameTag() {
    // Gets the nickName of the user
    return JSON.parse(this.props.streamManager.stream.connection.data)
      .clientData;
  }
  togglePlay = () => {
    this.setState((prevState) => ({
      isPlaying: !prevState.isPlaying,
    }));

    /* this.mutedStatus.current.textValue = this.state.isPlaying
      ? "True"
      : "False"; */

    if (this.state.isPlaying) {
      // this.videoRef.current.pause();
      //this.videoRef.muted = true;
    } else {
      // this.videoRef.current.play();
      //this.videoRef.muted = false;
    }
  };

  render() {
    const { isPlaying } = this.state;
    return (
      <>
        {this.props.streamManager !== undefined ? (
          <>
            <div className="streamcomponent">
              <OpenViduVideo
                muted={isPlaying}
                streamManager={this.props.streamManager}
              />
              <div>
                <p>{this.getNicknameTag()}</p>
              </div>
            </div>
            <div>
              <button
                onClick={this.togglePlay}
                style={{
                  border: "none",
                  outline: "none",
                  fontSize: "20px",
                  background: "none",
                }}
              >
                {isPlaying ? (
                  <i
                    className="fa fa-microphone-slash"
                    style={{ color: "red" }}
                    aria-hidden="true"
                  ></i>
                ) : (
                  <i className="fa fa-volume-up"></i>
                )}
              </button>
            </div>
          </>
        ) : null}
      </>
    );
  }
}
