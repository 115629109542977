import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import {
  DEFAULT_EXAM_TIMER,
  DEFAULT_EXAM_TIMER_KEY,
} from "../../../../components/Defaults";
import {
  // // getUserByToken,
  // // getUserBatch,
  // // getUserQuestionPaper,
  getUserQuestions,
} from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  // // UserRequested: "[Request User] Action",
  // // UserLoaded: "[Load User] Auth API",
  // // BatchLoaded: "[Load Batch] Auth API",
  // // QuestionPaperLoaded: "[Load QuestionPaper] Auth API",
  QuestionsLoaded: "[Load Questions] Action",
  // LanguageOptions: "[Load LanguageOptions] Action",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  // // batch: undefined,
  // // questionPaper: undefined,
  userType: undefined,
  questions: undefined,
  terms: undefined,
  Languages: undefined,
  assessmentDuration: undefined,
  captureInterval: undefined,
};

export const reducer = persistReducer(
  {
    storage,
    key: "tagnet-auth",
    whitelist: [
      "user",
      // // "batch",
      // // "questionPaper",
      "questions",
      "authToken",
      "userType",
      "terms",
      "Languages",
      "assessmentDuration",
      "captureInterval",
    ],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;
        // // return { authToken, user: undefined };
        // // console.log(authToken);
        const {
          Token: token,
          UserType: userType,
          Trainee: trainee,
          Assessor: assessor,
        } = authToken;
        return {
          authToken: token,
          userType: userType,
          user: userType === "trainee" ? trainee : assessor,
        };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        // // console.log("logout");
        localStorage.setItem(
          DEFAULT_EXAM_TIMER_KEY,
          JSON.stringify(DEFAULT_EXAM_TIMER)
        );
        return initialAuthState;
      }

      // // case actionTypes.UserLoaded: {
      // //   const { user } = action.payload;
      // //   return { ...state, user };
      // // }

      // // case actionTypes.BatchLoaded: {
      // //   const { batch } = action.payload;
      // //   return { ...state, batch };
      // // }

      // // case actionTypes.QuestionPaperLoaded: {
      // //   const { questionPaper } = action.payload;
      // //   return { ...state, questionPaper };
      // // }

      case actionTypes.QuestionsLoaded: {
        const { questionsData } = action.payload;
        const {
          Questions: questions,
          Terms: terms,
          Languages,
          AssessmentDuration: assessmentDuration,
          CaptureInterval: captureInterval,
        } = questionsData;
        return {
          ...state,
          questions,
          terms,
          Languages,
          assessmentDuration,
          captureInterval,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
  logout: () => ({ type: actionTypes.Logout }),
  // // requestUser: (user) => ({
  // //   type: actionTypes.UserRequested,
  // //   payload: { user },
  // // }),
  // // fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  // // fulfillBatch: (batch) => ({
  // //   type: actionTypes.BatchLoaded,
  // //   payload: { batch },
  // // }),
  // // fulfillQuestionPaper: (questionPaper) => ({
  // //   type: actionTypes.QuestionPaperLoaded,
  // //   payload: { questionPaper },
  // // }),
  fulfillQuestions: (questionsData) => ({
    type: actionTypes.QuestionsLoaded,
    payload: { questionsData },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga(actionData) {
    // // yield put(actions.requestUser());
    const { payload } = actionData;
    const { authToken } = payload;
    // // console.log(authToken);
    const { Trainee, UserType } = authToken;
    if (UserType === "trainee") {
      const { data: questions } = yield getUserQuestions(Trainee.EnrollmentNo);
      const { Data } = questions;
      yield put(actions.fulfillQuestions(Data));
    }
  });

  // // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  // //   const { data: user } = yield getUserByToken();
  // //   yield put(actions.fulfillUser(user));
  // // });

  // // yield takeLatest(actionTypes.UserLoaded, function* userLoaded() {
  // //   const { data: batch } = yield getUserBatch();
  // //   yield put(actions.fulfillBatch(batch));
  // // });

  // // yield takeLatest(actionTypes.BatchLoaded, function* batchLoaded() {
  // //   const { data: questionPaper } = yield getUserQuestionPaper();
  // //   yield put(actions.fulfillQuestionPaper(questionPaper));
  // // });

  // // yield takeLatest(
  // //   actionTypes.QuestionPaperLoaded,
  // //   function* questionPaperLoaded() {
  // //     const { data: questions } = yield getUserQuestions();
  // //     yield put(actions.fulfillQuestions(questions));
  // //   }
  // // );
}
