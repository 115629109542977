import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import SplashScreen from "../components/SplashScreen";
import PracticalClass from "./modules/Trainee/pages/PracticalClass";
import { PracticalAuthPage } from "./modules/Auth";

export default function TraineePractical() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  return (
    <Suspense fallback={<SplashScreen />}>
      <Switch>
        {!isAuthorized ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <Route>
            <PracticalAuthPage />
          </Route>
        ) : (
          /*Otherwise redirect to root page (`/`)*/
          <Redirect from="/practical" to="/trainee/practical/start" />
        )}
        {
          /* Redirect from root URL to /verify. */
          <Redirect exact from="/practical" to="/trainee/practical/start" />
        }
        <Route path="/trainee/practical/start" component={PracticalClass} />
        <Redirect to="error/error" />
      </Switch>
    </Suspense>
  );
}
