import React, { Suspense } from "react";
import { Button, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import SplashScreen from "../components/SplashScreen";
import Dashboard from "./modules/Assessor/pages/Dashboard";
import PracticalMonitor from "./modules/Assessor/pages/PracticalMonitor";
import TakePracticalClass from "./modules/Assessor/pages/TakePracticalClass";
import OjtMarksClass from "./modules/Assessor/pages/OjtMarks";
// // import TakePractical from "./modules/Assessor/pages/TakePractical";
import TheoryMonitorClass from "./modules/Assessor/pages/TheoryMonitorClass";
// // import TheoryMonitor from "./modules/Assessor/pages/TheoryMonitor";
import Verification from "./modules/Assessor/pages/Verification";
import VerificationStep2 from "./modules/Assessor/pages/VerificationStep2";
import * as assessorService from "./modules/Assessor/_redux/assessorRedux";
import * as auth from "./modules/Auth/_redux/authRedux";
import * as traineeService from "./modules/Trainee/_redux/traineeRedux";

function AssessorPage({ examLogout, practicalLogout, logout }) {
  return (
    <Suspense fallback={<SplashScreen />}>
      <Container>
        <nav className="navbar navbar-light bg-light">
          <span className="navbar-brand">Assessor Dashboard</span>
          <Button
            variant="outline-danger"
            className="my-2 my-sm-0"
            onClick={() => {
              examLogout();
              practicalLogout();
              logout();
            }}
          >
            Logout
          </Button>
        </nav>
        <Switch>
          {
            /* Redirect from root URL to /verify. */
            <Redirect exact from="/" to="/verify" />
          }
          <Route path="/verify" component={Verification} />
          <Route path="/verify-step-2" component={VerificationStep2} />
          <Route path="/dashboard" component={Dashboard} />
          <Route
            path="/assessment/practical/start"
            component={TakePracticalClass}
          />
          <Route path="/assessment/ojt/marks" component={OjtMarksClass} />
          <Route path="/assessment/practical" component={PracticalMonitor} />
          <Route path="/assessment/theory" component={TheoryMonitorClass} />
          {/* <Route path="/assessment/theory2" component={TheoryMonitor} />
          <Route path="/assessment/practical/start2" component={TakePractical} /> */}
          <Redirect to="error/error" />
        </Switch>
      </Container>
    </Suspense>
  );
}

export default connect(
  ({ auth }) => ({
    user: auth.user,
  }),
  { ...auth.actions, ...traineeService.actions, ...assessorService.actions }
)(AssessorPage);
