import React, { useEffect, useRef } from "react";
// changes by rohit
//import * as cocoSsd from "@tensorflow-models/coco-ssd";
import "@tensorflow/tfjs";
import swal from "sweetalert";
import axios from "axios";
import * as auth from "../../Auth/_redux/authRedux";
import * as traineeService from "../_redux/traineeRedux";
import { useHistory } from "react-router-dom";
import { DEFAULT_LOGOUT_EVENT } from "../../../../components/Defaults";

import { connect } from "react-redux";
import { formatLocalDate } from "../../../../helpers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const OPENVIDU_SERVER_URL = process.env.REACT_APP_OPENVIDU_SERVER_URL;
const OPENVIDU_SERVER_SECRET = process.env.REACT_APP_OPENVIDU_SERVER_SECRET;
const Detection = ({
  addExamEvent,
  setIsComplete,
  triggerGenerateResult,
  user: { EnrollmentNo, BatchId, ObjectDetection },
  modelPromise,
}) => {
  // const [alert, setAlert] = useState(null);
  //const { detection_videoRef, detection_photoRef, takeObjectPicture } = props;
  //   const dispatch = useDispatch();
  let history = useHistory();
  // const EnrollmentNo= user.EnrollmentNo;
  const detection_videoRef = useRef(null);
  const detection_photoRef = useRef(null);

  // - variable declared by rohit

  const state = { count: 0 };

  useEffect(() => {
    /* const res = axios.post('https://ai.tagsaathi.in/traineecreate', { "EnrollmentNo": EnrollmentNo}).catch((err) => {
      console.log("Issue in api Call", err);
    });
    console.log(res); */
    if (ObjectDetection === "1") {
      objDetection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ObjectDetection]);
  const showAlert = (type, msg) => {
    /*   setAlert({
        type,
        msg
      }) */
    //toast(msg);
    toast.error(msg, {
      toastId: "success1",
    });
    //const notify = () => toast("Face Not Visible!");
  };

  // -  variable declared by rohit end here
  //object Detection edited by rohit
  const objDetection = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const webCamPromise = navigator.mediaDevices
        .getUserMedia({
          video: {
            facingMode: "user",
            width: 800,
            height: 640,
          },
        })
        .then((stream) => {
          let video = detection_videoRef.current;
          window.stream = stream;
          video.srcObject = stream;
          video.play();

          // Detection code load on page load
          return new Promise((resolve, reject) => {
            video.onloadedmetadata = () => {
              resolve(video);
            };
          });
        });

      // load model and call the detectFrame function
      /* const modelPromise = await toast.promise(
        cocoSsd.load(),
        {
          pending: 'Please wait !',
          success: 'Exam started now ',
          error: 'Promise rejected 🤯'
        }
      );  */
      console.log("model com in", modelPromise);

      Promise.all([modelPromise, webCamPromise])
        .then((values) => {
          // detection_videoRef.current.width=720;

          detectFrame(detection_videoRef.current, values[0]);
        })
        .catch((error) => {
          console.error(error);
        });
      // Detection code
    }
  };
  const onComplete = async () => {
    setIsComplete(true);
    addExamEvent(
      EnrollmentNo,
      DEFAULT_LOGOUT_EVENT,
      formatLocalDate(new Date()),
      0
    );

    triggerGenerateResult(EnrollmentNo);
    let sessionID = `session_theory_${BatchId}_${EnrollmentNo}`;

    await fetch(
      OPENVIDU_SERVER_URL + "/openvidu/api/recordings/stop/" + sessionID,
      {
        method: "post",
        headers: {
          Authorization:
            "Basic " + btoa("OPENVIDUAPP:" + OPENVIDU_SERVER_SECRET),
        },
      }
    );
    history.push("/exam/thanks");
  };

  //---detection code logic rohit
  const detectFrame = (video, model) => {
    //console.log(video.videoWidth);
    // video = await detection_videoRef.current;
    model
      .detect(video)
      .then((predictions) => {
        if (detection_photoRef.current) {
          renderPredictions(predictions);
          requestAnimationFrame(() => {
            //  setTimeout(() => {
            detectFrame(video, model);
            //}, 700);
          });
        } else {
          return false;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const multiple_of_count = 5; // fixed or set by admin
  let increment_phone_count = 0; //increment by one
  let cellphone_detect_count = 0; // increase each time when cell detected

  let increment_prohibited_count = 0; //increment by one
  let prohibited_detect_count = 0; // increase each time when Prohibited object detected

  let increment_person_count = 0; //increment by one
  let person_detect_count = 0; // increase each time when person

  let increment_facenotfound_count = 0; //increment by one
  let facenotfound_count = 0; // increase each time when facenotfound

  let renderPredictions = (predictions) => {
    // setting up the canvas for drawing rectangles and printing
    // prediction text
    const ctx = detection_photoRef.current.getContext("2d");
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.font = "16px sans-serif";
    ctx.textBaseline = "top";

    // looping on predictions and drawing the bounding box for each object
    // and the text label background
    predictions.forEach((prediction) => {
      //console.log("coming")
      const x = prediction.bbox[0];
      const y = prediction.bbox[1];
      const width = prediction.bbox[2];
      const height = prediction.bbox[3];
      // Draw the bounding box.
      ctx.strokeStyle = "#00FFFF";
      ctx.lineWidth = 2;
      ctx.strokeRect(x, y, width, height);
      // Draw the label background.
      ctx.fillStyle = "#00FFFF";
      const textWidth = ctx.measureText(prediction.class).width;
      const textHeight = parseInt("16px sans-serif", 10); // base 10
      ctx.fillRect(x, y, textWidth + 8, textHeight + 8);
    });

    // Looping over all predictions and drawing text (prediction class)
    predictions.forEach((prediction) => {
      const x = prediction.bbox[0];
      const y = prediction.bbox[1];

      ctx.fillStyle = "#000000";

      // Draw the text last to ensure it's on top.
      if (
        prediction.class === "person" ||
        prediction.class === "cell phone" ||
        prediction.class === "book" ||
        prediction.class === "laptop"
      ) {
        ctx.fillText(prediction.class, x, y);
      }
    });

    var faces = 0;

    // if face is not visible till 50 consecutive frames, student is
    // not in front of the computer, throw an error
    if (predictions.length === 0 && state.count < 50) {
      state.count++;
    } else if (predictions.length === 0) {
      state.count = 0;
      if (
        facenotfound_count ===
        increment_facenotfound_count * multiple_of_count
      ) {
        objectCapture("facenotfound");
        increment_facenotfound_count++;
      }
      facenotfound_count += 1;
      console.log("Face Not Visible");

      //showAlert("danger", "Face Not Visible");
      showAlert("danger", "Face Not Visible");
      //  swal("Face Not Visible", "Action has been Recorded", "error");
    }

    // loop over all predictions and check if mobile phone, book, laptop or multiple
    // people are there in the frame
    for (let i = 0; i < predictions.length; i++) {
      if (predictions[i].class === "cell phone") {
        console.log("Cell Phone Detected" + cellphone_detect_count);

        showAlert("danger", "Cell Phone Detected");
        // swal("Cell Phone Detected", "Action has been Recorded", "error");
        if (
          cellphone_detect_count ===
          increment_phone_count * multiple_of_count
        ) {
          objectCapture("mobile");
          increment_phone_count++;
        }
        cellphone_detect_count += 1;
      } else if (
        predictions[i].class === "book" ||
        predictions[i].class === "laptop"
      ) {
        // swal("Prohibited Object Detected", "Action has been Recorded", "error");
        console.log("Prohibited Object Detected");
        showAlert("danger", "Prohibited Object Detected");
        if (
          prohibited_detect_count ===
          increment_prohibited_count * multiple_of_count
        ) {
          objectCapture("prohibited");
          increment_prohibited_count++;
        }
        prohibited_detect_count += 1;
      } else if (predictions[i].class === "person") {
        faces += 1;
        state.count = 0;
      }
    }
    if (faces > 1) {
      console.log("Multi face detected", faces.toString());
      // takePhoto();
      showAlert("danger", faces.toString() + " people detected");
      if (person_detect_count === increment_person_count * multiple_of_count) {
        objectCapture("person");
        increment_person_count++;
      }
      person_detect_count += 1;
      //swal(faces.toString()+" people detected", "Action has been recorded", "error");
    }
  };

  //-- end detection code with logic
  const takeObjectPicture = () => {
    /* getUserMedia(constraints)
      .then((stream) => {
        const video = document.querySelector("video");
        video.srcObject = stream;
        video.play();
      })
      .catch((err) => {
        console.log(err);
      }); */
    let video = detection_videoRef.current;
    //const canvas = document.querySelector("canvas");
    const canvas = detection_photoRef.current;
    const context = canvas.getContext("2d");
    //const video = document.querySelector("video");
    //  const { width, height } = constraints.video;
    // canvas.width = width;
    //canvas.height = height;
    //  context.drawImage(video, 0, 0, width, height);

    context.drawImage(video, 0, 0, video.width, video.height);
    console.log(video.width, video.height);
    const data = canvas.toDataURL("image/png");
    // // TODO: post image to api

    // uploadPicture(data);
    return data;
    // clearPhoto();
  };

  const objectCapture = async (objectName) => {
    const capimage = await takeObjectPicture();

    let capturePhoto = capimage.replace("data:image/png;base64,", "");
    const response = await axios
      .post(process.env.REACT_APP_API_URL + "/api/updateobjstatus", {
        EnrollmentNo: EnrollmentNo,
        objectName,
        capturePhoto: capturePhoto,
      })
      .catch((err) => {
        console.log("Issue in api Call", err);
      });

    if (response && response.status === 200) {
      if (response.data.flag === "warning") {
        swal(
          "Exam Warning",
          "Your exam will be terminated any time.",
          "warning"
        );
      }
      if (response.data.flag === "terminated") {
        swal({
          title: "Exam Terminated",
          text: "Your exam has been terminated now",
          icon: "warning",
          dangerMode: true,
        }).then((ok) => {
          if (ok) {
            console.log("aa gya");
            swal.close();
            onComplete();

            //	return navigate('/report')
          }
        });

        setTimeout(() => {
          swal.close();
          onComplete();
        }, 5000);

        //return redirectpage ? return navigate('/signin') : "";
      }
      /* console.log(response.data.flag);
      console.log(response.data.score); */
      //alert("successfully uploaded")
      //showAlert("success","Image uploaded successfully");
    } else {
      showAlert("danger", "Somthing went wrong");
    }
  };
  // object detection by rohit
  const styles = {
    picSize: {
      width: 320,
      height: 240,
    },
  };
  return (
    <>
      <canvas
        id="canvas"
        ref={detection_photoRef}
        width="320"
        height="240"
        style={styles.picSize}
        hidden
      ></canvas>
      <video
        className="mt-1"
        ref={detection_videoRef}
        width="320"
        height="240"
        hidden
      />
      <ToastContainer autoClose={1500} hideProgressBar />
    </>
  );
};
export default connect(({ auth }) => ({ user: auth.user }), {
  ...auth.actions,
  ...traineeService.actions,
})(Detection);
