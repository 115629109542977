import React, { useState, useEffect } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { DEFAULT_EXAM_TIMER_KEY } from "./Defaults";

function Timer(props) {
  const { assessmentDuration, onComplete } = props;
  const [elapsed, setElapsed] = useState(0);
  useEffect(() => {
    const data = localStorage.getItem(DEFAULT_EXAM_TIMER_KEY);
    if (data) {
      setElapsed(JSON.parse(data));
    }
  }, []);
  useEffect(() => {
    localStorage.setItem(DEFAULT_EXAM_TIMER_KEY, JSON.stringify(elapsed));
  }, [elapsed]);
  const onTick = () => {
    setElapsed(elapsed + 1000);
  };
  const countdownRenderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>00:00:00</span>;
    } else {
      return (
        <span>
          {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      );
    }
  };
  return (
    <>
      Time:
      <Countdown
        date={Date.now() + assessmentDuration * 60 * 1000 - elapsed}
        renderer={countdownRenderer}
        onTick={onTick}
        onComplete={onComplete}
      />
    </>
  );
}

export default Timer;
