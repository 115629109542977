import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import SplashScreen from "../components/SplashScreen";
import ExamPage from "./modules/Trainee/pages/ExamPage";
// // import PracticalClass from "./modules/Trainee/pages/PracticalClass";
// // import Practical from "./modules/Trainee/pages/Practical";
import Verification from "./modules/Trainee/pages/Verification";
import VerificationStep2 from "./modules/Trainee/pages/VerificationStep2";

export default function TraineePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<SplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /verify. */
          <Redirect exact from="/" to="/verify" />
        }
        <Route path="/verify" component={Verification} />
        <Route path="/verify-step-2" component={VerificationStep2} />
        <Route path="/exam" component={ExamPage} />
        {/* <Route path="/practical" component={PracticalClass} /> */}
        {/* <Route path="/practical2" component={Practical} /> */}
        <Redirect to="error/error" />
      </Switch>
    </Suspense>
  );
}
