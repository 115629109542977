import React, { useRef, useState, useLayoutEffect } from "react";
//import { ReactDOM } from "react";
import "../style/typingStyles.css";
const Typingtest = (data) => {
  // alert(data.text);
  // define the time limit

  // define quotes to be used
  /* let quotes_array = [
  "Push yourself, because no one else is going to do it for you.",
  "Failure is the condiment that gives success its flavor.",
  "Wake up with determination. Go to bed with satisfaction.",
  "It's going to be hard, but hard does not mean impossible.",
  "Learning never exhausts the mind.",
  "The only way to do great work is to love what you do."
]; */
  let quotes_array = [data.text];

  // selecting required elements
  let quoteRef = useRef(quotes_array[0]);
  let timertextRef = useRef(null);
  let cpmtextRef = useRef();
  let wpmtextRef = useRef();
  let inputareaRef = useRef();
  let errorsRef = useRef();
  let accuracyRef = useRef();

  /* let timer_text;
  let accuracy_text;
  let error_text;
  let cpm_text;
  let wpm_text ;
  let quote_text ;
  let input_area ;
  let restart_btn ;
  let cpm_group ;
  let wpm_group ;


     timer_text = document.querySelector(".curr_time");
     accuracy_text = document.querySelector(".curr_accuracy");
     error_text = document.querySelector(".curr_errors");
     cpm_text = document.querySelector(".curr_cpm");
     wpm_text = document.querySelector(".curr_wpm");
     quote_text = quoteRef.current;
     input_area = document.querySelector(".input_area");
     restart_btn = document.querySelector(".restart_btn");
     cpm_group = document.querySelector(".cpm");
     wpm_group = document.querySelector(".wpm"); */

  //let error_group = document.querySelector(".errors");
  //let accuracy_group = document.querySelector(".accuracy");
  let TIME_LIMIT = 60;
  let [timeleft, setTimeLeft] = useState(TIME_LIMIT);
  let [time_elapsed, setTimeElapsed] = useState(0);
  let [total_errors, setTotalErrors] = useState(0);
  let [errors, setErrors] = useState(0);
  let [character_typed, setCharacterTyped] = useState(0);
  let [current_quote, setCurrentQuote] = useState("");
  let [timer, setTimer] = useState(null);
  let quoteNo = 0;
  /* let timeLeft = TIME_LIMIT;
let timeElapsed = 0;
let total_errors = 0;
let errors = 0;
let accuracy = 0;
let characterTyped = 0;
let current_quote = "";
let quoteNo = 0;
let timer = null; */

  function updateQuote() {
    quoteRef.current.textContent = null;
    current_quote = quotes_array[0];
    //alert(current_quote);
    //})
    // separate each character and make an element
    // out of each of them to individually style them
    current_quote.split("").forEach((char) => {
      const charSpan = document.createElement("span");
      charSpan.innerText = char;
      quoteRef.current.appendChild(charSpan);
    });

    // roll over to the first quote
    if (quoteNo < quotes_array.length - 1) quoteNo++;
    else quoteNo = 0;
  }
  function processCurrentText() {
    // get current input text and split it
    let curr_input = inputareaRef.current.value;
    let curr_input_array = curr_input.split("");

    // increment total characters typed
    character_typed++;

    errors = 0;

    let quoteSpanArray = quoteRef.current.querySelectorAll("span");
    quoteSpanArray.forEach((char, index) => {
      let typedChar = curr_input_array[index];

      // characters not currently typed
      if (typedChar == null) {
        char.classList.remove("correct_char");
        char.classList.remove("incorrect_char");

        // correct characters
      } else if (typedChar === char.innerText) {
        char.classList.add("correct_char");
        char.classList.remove("incorrect_char");

        // incorrect characters
      } else {
        char.classList.add("incorrect_char");
        char.classList.remove("correct_char");

        // increment number of errors
        errors++;
      }
    });

    // display the number of errors
    errorsRef.current.textContent = total_errors + errors;

    // update accuracy text
    let correctCharacters = character_typed - (total_errors + errors);
    let accuracyVal = (correctCharacters / character_typed) * 100;
    accuracyRef.current.textContent = Math.round(accuracyVal);

    // if current text is completely typed
    // irrespective of errors
    if (curr_input.length === current_quote.length) {
      updateQuote();

      // update total errors
      total_errors += errors;

      // clear the input area
      //inputareaRef.current.value = "";
      inputareaRef.current.disabled = true;
      finishGame();
    }
  }

  function updateTimer() {
    // document.addEventListener("DOMContentLoaded", function(event) {
    if (timeleft > 0) {
      if (timertextRef.current) {
        // decrease the current time left
        timeleft--;

        // increase the time elapsed
        time_elapsed++;
        //console.log(timeleft);
        // update the timer text

        timertextRef.current.textContent = timeleft + "s";

        // calculate cpm and wpm
        let cpm = Math.round((character_typed / time_elapsed) * 60);
        let wpm = Math.round((character_typed / 5 / time_elapsed) * 60);

        // update cpm and wpm text
        cpmtextRef.current.textContent = cpm;
        wpmtextRef.current.textContent = wpm;
      }
    } else {
      // finish the game
      finishGame();
    }
    //})
  }

  function finishGame() {
    // console.log(wpmtextRef.current);
    // console.log(cpmtextRef);
    clearInterval(timer);
    /* console.log(wpmtextRef.current);
    console.log(cpmtextRef); */
    //  console.log(wpmtextRef.current.textContent);
    const typingsummery = {
      wpm: wpmtextRef.current.textContent,
      cpm: cpmtextRef.current.textContent,
      errors: errorsRef.current.textContent,
      accuracy: accuracyRef.current.textContent,
      input: inputareaRef.current.value,
    };
    // console.log(typingsummery);
    //document.addEventListener("DOMContentLoaded", function(event) {
    // stop the timer
    clearInterval(timer);
    // disable the input area
    inputareaRef.current.disabled = true;
    localStorage.setItem("typingteststatus", JSON.stringify(typingsummery));
    //cpm_group.style.display = "block";
    //wpm_group.style.display = "block";
    //});

    // show finishing text
    // quote_text.textContent = "Click on restart to start a new game.";

    // display restart button
    //restart_btn.style.display = "none";

    // display the cpm and wpm
  }

  function startGame() {
    if (!localStorage.getItem("typingteststatus")) {
      // resetValues();
      updateQuote();

      // clear old and start a new timer
      // clearInterval(timer);
      if (timertextRef) {
        timer = setInterval(updateTimer, 1000);
      } else {
        clearInterval(timer);
      }
    } else {
    }
  }
  useLayoutEffect(() => {
    if (localStorage.getItem("typingteststatus")) {
      let summary = JSON.parse(localStorage.getItem("typingteststatus"));
      inputareaRef.current.disabled = true;
      //console.log(summary['wpm']);
      timertextRef.current.textContent = "0s";
      wpmtextRef.current.textContent = summary["wpm"];
      cpmtextRef.current.textContent = summary["cpm"];
      errorsRef.current.textContent = summary["errors"];
      accuracyRef.current.textContent = summary["accuracy"];
      inputareaRef.current.value = summary["input"];
    }
  }, []);

  function resetValues() {
    /* document.addEventListener("DOMContentLoaded", function(event) {
    timeLeft = TIME_LIMIT;
  timeElapsed = 0;
  errors = 0;
  total_errors = 0;
  accuracy = 0;
  characterTyped = 0;
  quoteNo = 0;
   
    input_area.disabled = false;
    input_area.value = "";
    accuracy_text.textContent = 100;
    timer_text.textContent = timeLeft + 's';
    error_text.textContent = 0;
  });
   */
    //quote_text.textContent = 'Click on the area below to start the game.';
    //restart_btn.style.display = "none";
    /* cpm_group.style.display = "none";
  wpm_group.style.display = "none"; */
  }
  return (
    <div className="container">
      <div className="header">
        <div className="wpm col-md-2">
          <div className="header_text">WPM</div>
          <div className="curr_wpm" ref={wpmtextRef}>
            0
          </div>
        </div>

        <div className="cpm col-md-2">
          <div className="header_text">CPM</div>
          <div className="curr_cpm" ref={cpmtextRef}>
            0
          </div>
        </div>
        <div className="errors col-md-2">
          <div className="header_text">Errors</div>
          <div className="curr_errors" ref={errorsRef}>
            0
          </div>
        </div>
        <div className="timer col-md-2">
          <div className="header_text">Time</div>
          <div className="curr_time" ref={timertextRef}>
            60s
          </div>
        </div>
        <div className="accuracy col-md-2">
          <div className="header_text">% Accuracy</div>
          <div className="curr_accuracy" ref={accuracyRef}>
            0
          </div>
        </div>
      </div>
      <div className="quote" ref={quoteRef}>
        {data.text}
      </div>
      <textarea
        style={{ height: 100 }}
        ref={inputareaRef}
        className="input_area"
        width="100%"
        placeholder="start typing here..."
        onInput={processCurrentText}
        onFocus={startGame}
      />

      {/* <button class="restart_btn" onClick={resetValues}>Restart</button>  */}
    </div>
  );
};
export default Typingtest;
