import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
// // import axios from "axios";
import { connect } from "react-redux";
import * as auth from "../../Auth/_redux/authRedux";

function Dashboard({ user, history }) {
  return (
    <Container>
      <Row>
        <Col md={3} sm={12}></Col>
        <Col md={6} sm={12} className="border-right border-left border-top">
          <Row>
            <Col md={6} sm={12} className="border-bottom border-right">
              Batch Id
            </Col>
            <Col md={6} sm={12} className="border-bottom">
              {user.BatchId}
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={12} className="border-bottom border-right">
              Center
            </Col>
            <Col md={6} sm={12} className="border-bottom">
              {user.CenterName}
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={12} className="border-bottom border-right">
              Training Partner
            </Col>
            <Col md={6} sm={12} className="border-bottom">
              {user.PartnerName}
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={12} className="border-bottom border-right">
              Center Id
            </Col>
            <Col md={6} sm={12} className="border-bottom">
              {user.CenterId}
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={12} className="border-bottom border-right">
              Center Telephone
            </Col>
            <Col md={6} sm={12} className="border-bottom">
              {user.Tel}
            </Col>
          </Row>
          <Row>
            <Col
              md={6}
              sm={12}
              className="border-bottom border-right text-center p-2"
            >
              <Button
                variant="info"
                onClick={() => {
                  history.push("/assessment/practical");
                }}
              >
                Take Pratical
              </Button>
            </Col>
            <Col md={6} sm={12} className="border-bottom text-center p-2">
              <Button
                variant="success"
                onClick={() => {
                  history.push("/assessment/theory");
                }}
              >
                Monitor Theory
              </Button>
            </Col>
          </Row>
        </Col>
        <Col md={3} sm={12}></Col>
      </Row>
    </Container>
  );
}

export default connect(
  ({ auth }) => ({
    user: auth.user,
  }),
  auth.actions
)(Dashboard);
