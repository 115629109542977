import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const EXAM_EVENT_URL = `${API_URL}/api/examevent`;
export const QUESTION_RESPONSE_URL = `${API_URL}/api/answersingle`;
export const GENERATE_RESULT_URL = `${API_URL}/api/generateresult`;

export const addExamEvent = async (enrollmentNo, type, time, questionNo) => {
  return await axios.post(EXAM_EVENT_URL, {
    EnrollmentNo: enrollmentNo,
    Type: type,
    Time: time,
    QuestionNo: questionNo,
  });
};

export const saveQuestionResponse = async (
  enrollmentNo,
  questionId,
  startTime,
  endTime,
  responses
) => {
  return await axios.post(QUESTION_RESPONSE_URL, {
    EnrollmentNo: enrollmentNo,
    Answer: {
      QuestionId: questionId,
      StartTime: startTime,
      EndTime: endTime,
      Resonpses: responses,
    },
  });
};

export const triggerGenerateResult = async (enrollmentNo) => {
  return await axios.post(GENERATE_RESULT_URL, {
    EnrollmentNo: enrollmentNo,
  });
};
