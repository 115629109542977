import axios from "axios";
import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { OpenVidu } from "openvidu-browser";
import { connect } from "react-redux";
import * as auth from "../../Auth/_redux/authRedux";
import * as assessorService from "../../Assessor/_redux/assessorRedux";
import * as traineeService from "../_redux/traineeRedux";
import UserVideoComponent from "../../../../components/OpenViduVideo";

const OPENVIDU_SERVER_URL = "https://test.tagindia.co.in";
const OPENVIDU_SERVER_SECRET = "_g0h_fektCm8VxSoUjSZJLdKqLpr1E-7n480XjPHnFc";

class PracticalClass extends Component {
  constructor(props) {
    super(props);
    const { user } = props;
    this.state = {
      mySessionId: user
        ? `session_practical_${user.BatchId}_${user.EnrollmentNo}`
        : ``,
      myUserName: user ? (user.Name ? user.Name : `Trainee`) : `Trainee`,
      session: undefined,
      publisher: undefined,
      subscribers: [],
    };
    this.joinSession = this.joinSession.bind(this);
    this.leaveSession = this.leaveSession.bind(this);
    this.handleChangeSessionId = this.handleChangeSessionId.bind(this);
    this.handleChangeUserName = this.handleChangeUserName.bind(this);
    this.handleMainVideoStream = this.handleMainVideoStream.bind(this);
    this.onbeforeunload = this.onbeforeunload.bind(this);
  }

  componentDidMount() {
    this.joinSession();
    window.addEventListener("beforeunload", this.onbeforeunload);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onbeforeunload);
  }

  onbeforeunload(event) {
    this.leaveSession();
  }

  handleChangeSessionId(e) {
    this.setState({
      mySessionId: e.target.value,
    });
  }

  handleChangeUserName(e) {
    this.setState({
      myUserName: e.target.value,
    });
  }

  handleMainVideoStream(stream) {
    if (this.state.mainStreamManager !== stream) {
      this.setState({
        mainStreamManager: stream,
      });
    }
  }

  deleteSubscriber(streamManager) {
    let subscribers = this.state.subscribers;
    let index = subscribers.indexOf(streamManager, 0);
    if (index > -1) {
      subscribers.splice(index, 1);
      this.setState({
        subscribers: subscribers,
      });
    }
  }

  joinSession() {
    this.OV = new OpenVidu();

    this.setState(
      {
        session: this.OV.initSession(),
      },
      () => {
        var mySession = this.state.session;

        mySession.on("streamCreated", (event) => {
          var subscriber = mySession.subscribe(event.stream, undefined);
          var subscribers = this.state.subscribers;
          subscribers.push(subscriber);

          this.setState({
            subscribers: subscribers,
          });
          console.log(
            "🚀 ~ file: TheoryMonitorClass.js ~ line 106 ~ ov_session.on streamCreated ~ streamId",
            event.stream.streamId
          );
        });

        mySession.on("streamDestroyed", (event) => {
          this.deleteSubscriber(event.stream.streamManager);
          console.log(
            "🚀 ~ file: TheoryMonitorClass.js ~ line 116 ~ ov_session.on streamDestroyed ~ streamId",
            event.stream.streamId
          );
        });

        this.getToken().then((token) => {
          mySession
            .connect(token, { clientData: this.state.myUserName })
            .then(() => {
              this.OV.getDevices().then((devices) => {
                var videoDevices = devices.filter(
                  (device) => device.kind === "videoinput"
                );
                var videoSource = undefined;
                if (videoDevices && videoDevices.length > 1) {
                  // // In mobile devices the default and first camera is the front one
                  videoSource = videoDevices[1].deviceId;
                } else {
                }
                const user_video = this.OV.initPublisher(undefined, {
                  audioSource: undefined, // The source of audio. If undefined default microphone
                  videoSource: videoSource, // The source of video. If undefined default webcam
                  publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
                  publishVideo: true, // Whether you want to start publishing with your video enabled or not
                  resolution: "640x480", // The resolution of your video
                  frameRate: 30, // The frame rate of your video
                  insertMode: "APPEND", // How the video is inserted in the target element 'video-container'
                  mirror: false, // Whether to mirror your local video or not
                });
                mySession.publish(user_video);
              });
            })
            .catch((error) => {
              console.log(
                "There was an error connecting to the session:",
                error.code,
                error.message
              );
            });
        });
      }
    );
  }

  leaveSession() {
    const { user, examLogout, practicalLogout, logout } = this.props;
    const mySession = this.state.session;
    if (mySession) {
      mySession.disconnect();
    }
    this.OV = null;
    this.setState({
      session: undefined,
      subscribers: [],
      mySessionId: user
        ? `session_practical_${user.BatchId}_${user.EnrollmentNo}`
        : ``,
      myUserName: user ? (user.Name ? user.Name : `Trainee`) : `Trainee`,
      mainStreamManager: undefined,
      publisher: undefined,
    });
    examLogout();
    practicalLogout();
    logout();
  }

  getToken() {
    return this.createSession(this.state.mySessionId).then((sessionId) =>
      this.createToken(sessionId)
    );
  }

  createSession(sessionId) {
    return new Promise((resolve, reject) => {
      var data = JSON.stringify({
        customSessionId: sessionId,
        recordingMode: "ALWAYS",
        defaultOutputMode: "INDIVIDUAL",
      });
      const instance = axios.create();
      instance
        .post(OPENVIDU_SERVER_URL + "/api/sessions", data, {
          headers: {
            Authorization:
              "Basic " + btoa("OPENVIDUAPP:" + OPENVIDU_SERVER_SECRET),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("CREATE SESION", response);
          resolve(response.data.id);
        })
        .catch((response) => {
          var error = Object.assign({}, response);
          if (error.response.status === 409) {
            resolve(sessionId);
          } else {
            console.log(error);
            console.warn(
              "No connection to OpenVidu Server. This may be a certificate error at " +
                OPENVIDU_SERVER_URL
            );
            if (
              window.confirm(
                'No connection to OpenVidu Server. This may be a certificate error at "' +
                  OPENVIDU_SERVER_URL +
                  '"\n\nClick OK to navigate and accept it. ' +
                  'If no certificate warning is shown, then check that your OpenVidu Server is up and running at "' +
                  OPENVIDU_SERVER_URL +
                  '"'
              )
            ) {
              window.location.assign(
                OPENVIDU_SERVER_URL + "/accept-certificate"
              );
            }
          }
        });
    });
  }

  createToken(sessionId) {
    return new Promise((resolve, reject) => {
      var data = JSON.stringify({ session: sessionId });
      const instance = axios.create();
      instance
        .post(OPENVIDU_SERVER_URL + "/api/tokens", data, {
          headers: {
            Authorization:
              "Basic " + btoa("OPENVIDUAPP:" + OPENVIDU_SERVER_SECRET),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("TOKEN", response);
          resolve(response.data.token);
        })
        .catch((error) => reject(error));
    });
  }

  render() {
    return (
      <Row>
        <Col md={8}>
          <div className="container">
            {this.state.session !== undefined ? (
              <div id="session">
                <div id="session-header">
                  <h4 id="session-title" className="text-center">
                    Practical Exam
                  </h4>
                </div>

                {this.state.subscribers !== undefined &&
                this.state.subscribers.length > 0 ? (
                  <div className="row mb-3">
                    <div id="main-video" className="col-md-12">
                      <UserVideoComponent
                        streamManager={this.state.subscribers[0]}
                      />
                    </div>
                  </div>
                ) : (
                  <>Waiting for Assessor to join!</>
                )}
              </div>
            ) : (
              <>Waiting for Assessor to join!</>
            )}
          </div>
        </Col>
        <Col md={4}>
          <Button
            variant="danger"
            onClick={() => {
              this.leaveSession();
            }}
          >
            End Practical Exam
          </Button>
        </Col>
      </Row>
    );
  }
}

export default connect(
  ({ auth }) => ({
    user: auth.user,
  }),
  { ...auth.actions, ...assessorService.actions, ...traineeService.actions }
)(PracticalClass);
