import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import * as auth from "../../Auth/_redux/authRedux";
import * as assessorService from "../_redux/assessorRedux";

class OjtMarksClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValues: {}, // Initialize input value
      responses: [], // State to store all responses
    };
  }

  handleInputChange = (index, event) => {
    const { value } = event.target;
    // console.log(this.props.assessorService.Ojt[index]);
    // return;
    // const { Marks } = this.props.assessorService.Ojt[index];
    const { Id, Marks } = this.props.assessorService.Ojt[index]; // Extract ID and Marks

    // Validate if value is a number and not greater than Marks
    if (/^\d*\.?\d*$/.test(value) && (value === "" || Number(value) <= Marks)) {
      this.setState((prevState) => ({
        inputValues: {
          ...prevState.inputValues,
          [index]: value,
        },
      }));

      // Update responses directly
      this.setState((prevState) => {
        const newResponses = prevState.responses.filter(
          (response) => response.id !== Id
        );
        newResponses.push({
          id: Id,
          Marks: value || "", // Use empty string if no input
        });
        return {
          responses: newResponses, // Update responses state directly
        };
      });
    }
  };
  handleSave = () => {
    const {
      history,
      assessorService: { selectedTrainee },
    } = this.props;
    const { Ojt } = this.props.assessorService;
    const { responses } = this.state;
    console.log("Saved Responses:", selectedTrainee);
    let count = 0;
    Ojt.map((inx) => {
      count++;
    });
    if (count !== responses.length) {
      alert("Please fill all ojt marks !");
    } else {
      this.props.saveOjtResponse(selectedTrainee, responses);
      this.props.triggerGenerateResult(selectedTrainee);
      history.goBack();
    }
  };
  handleCancel = () => {
    const { history } = this.props;
    history.goBack();
  };

  // console.log(scenarios);

  render() {
    const {
      user,
      assessorService: { selectedTrainee, Ojt },
    } = this.props;

    return (
      <Row>
        <Col md={8}>
          <div className="mb-1 ">
            <h5 id="session-title" className="text-center mt-2">
              OJT Marks for Trainee {selectedTrainee ? selectedTrainee : ``} of
              Batch Id - {user ? user.BatchId : ``}
            </h5>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th width="10%">Sr.#</th>
                  <th>Question</th>
                  <th>Marks</th>
                  <th width="25%">Marks Obtain</th>
                </tr>
              </thead>
              <tbody>
                {Ojt.map((ojtitem, idx) => {
                  return (
                    <tr key={ojtitem.Id}>
                      <td>{idx + 1}</td>
                      <td>{ojtitem.Text}</td>
                      <td>{ojtitem.Marks}</td>
                      <td>
                        <div>
                          <p className="mb-0">
                            <input
                              type="text"
                              value={this.state.inputValues[idx] || ""}
                              onChange={(event) =>
                                this.handleInputChange(idx, event)
                              }
                              placeholder="Enter Number"
                              className="border-bottom "
                            />
                          </p>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Button variant="danger" onClick={this.handleSave}>
            Save Marks
          </Button>
          <Button
            variant="secondary"
            onClick={this.handleCancel}
            className="ml-2"
          >
            Cancel
          </Button>
        </Col>
      </Row>
    );
  }
}
export default connect(
  ({ auth, assessorService }) => ({
    user: auth.user,
    assessorService: assessorService,
  }),
  { ...auth.actions, ...assessorService.actions }
)(OjtMarksClass);
