// // import React from "react";
// // import { Redirect } from "react-router-dom";

export default function setupAxios(axios, store) {
  // // const REFRESH_URL = `${process.env.REACT_APP_API_URL}/api/token/refresh/`;
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );

  // // axios.interceptors.response.use(
  // //   (response) => {
  // //     return response;
  // //   },
  // //   (err) => {
  // //     const originalRequest = err.config;

  // //     if (err.response.status === 401 && originalRequest.url === REFRESH_URL) {
  // //       return <Redirect to="/auth/login" />;
  // //       //return Promise.reject(err);
  // //     }

  // //     if (err.response.status === 401 && !originalRequest._retry) {
  // //       originalRequest._retry = true;
  // //       const {
  // //         auth: { refreshToken },
  // //       } = store.getState();
  // //       return axios
  // //         .post(REFRESH_URL, {
  // //           refresh: refreshToken,
  // //         })
  // //         .then((res) => {
  // //           if (res.status === 201) {
  // //             let authToken = res.access;
  // //             store.setState({ authToken });
  // //             axios.defaults.headers.common["Authorization"] =
  // //               "Bearer " + authToken;
  // //             return axios(originalRequest);
  // //           }
  // //         });
  // //     }
  // //     return Promise.reject(err);
  // //   }
  // // );
}
