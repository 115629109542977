import React from "react";
import { CircularProgress } from "@material-ui/core";
import { toAbsoluteUrl } from "../helpers";

class SplashScreen extends React.Component {
  render() {
    return (
      <>
        <div className={`splash-screen`}>
          <img src={toAbsoluteUrl("/medias/logos/logo.png")} alt="Tagnet 2.0" />
          <CircularProgress />
        </div>
      </>
    );
  }
}

export default SplashScreen;
