import axios from "axios";

const OPENVIDU_SERVER_URL = process.env.REACT_APP_OPENVIDU_SERVER_URL;
const OPENVIDU_SERVER_SECRET = process.env.REACT_APP_OPENVIDU_SERVER_SECRET;
const createSession = async (sessionId) => {
  try {
    var data = JSON.stringify({
      customSessionId: sessionId,
      recordingMode: "ALWAYS",
      defaultOutputMode: "INDIVIDUAL",
    });
    const instance = axios.create();
    const {
      data: { id },
    } = await instance.post(OPENVIDU_SERVER_URL + "/api/sessions", data, {
      headers: {
        Authorization: "Basic " + btoa("OPENVIDUAPP:" + OPENVIDU_SERVER_SECRET),
        "Content-Type": "application/json",
      },
    });
    return id;
  } catch (error) {
    // // var error = Object.assign({}, response);
    if (error.response && error.response.status === 409) {
      return sessionId;
    } else {
      console.log(error);
      console.warn(
        "No connection to OpenVidu Server. This may be a certificate error at " +
          OPENVIDU_SERVER_URL
      );
      if (
        window.confirm(
          'No connection to OpenVidu Server. This may be a certificate error at "' +
            OPENVIDU_SERVER_URL +
            '"\n\nClick OK to navigate and accept it. ' +
            'If no certificate warning is shown, then check that your OpenVidu Server is up and running at "' +
            OPENVIDU_SERVER_URL +
            '"'
        )
      ) {
        window.location.assign(OPENVIDU_SERVER_URL + "/accept-certificate");
      }
    }
  }
};

const createToken = async (sessionId) => {
  try {
    var data = JSON.stringify({ session: sessionId });
    const instance = axios.create();
    const {
      data: { token },
    } = await instance.post(OPENVIDU_SERVER_URL + "/api/tokens", data, {
      headers: {
        Authorization: "Basic " + btoa("OPENVIDUAPP:" + OPENVIDU_SERVER_SECRET),
        "Content-Type": "application/json",
      },
    });
    return token;
  } catch (error) {
    console.error(error);
  }
};

export const getToken = async (sessId) => {
  try {
    const sessionId = await createSession(sessId);
    return await createToken(sessionId);
  } catch (error) {
    console.error(error);
  }
};
