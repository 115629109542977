import axios from "axios";
// import userTableMock from "../__mocks__/userTableMock";

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/api/login`;

// // export const ME_URL = `${process.env.REACT_APP_API_URL}/api/me/`;

// // export const BATCH_URL = `${process.env.REACT_APP_API_URL}/api/batch/`;

// // export const QUESTION_PAPER_URL = `${process.env.REACT_APP_API_URL}/api/questionpaper/`;

export const QUESTIONS_URL = `${process.env.REACT_APP_API_URL}/api/questions`;

export const login = async (username, password) => {
  // // return axios.post(LOGIN_URL, { username, password });
  return await axios.post(LOGIN_URL, {
    username: username,
    password: password,
    Source: "Web",
  });
};

// // export function getUserByToken() {
// //   // const user = userTableMock.find((x) => x.id === 1);
// //   // return { data: { ...user, password: undefined } };
// //   // Authorization head should be fulfilled in interceptor.
// //   return axios.get(ME_URL);
// // }

// // export function getUserBatch() {
// //   return axios.get(BATCH_URL);
// // }

// // export function getUserQuestionPaper() {
// //   return axios.get(QUESTION_PAPER_URL);
// // }

export const getUserQuestions = async (EnrollmentNo) => {
  return await axios.post(QUESTIONS_URL, { EnrollmentNo });
};
