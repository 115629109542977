import axios from "axios";
import React, { Component, createRef } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { OpenVidu } from "openvidu-browser";
import { connect } from "react-redux";
// // import * as tf from "@tensorflow/tfjs";
// // import * as cocossd from "@tensorflow-models/coco-ssd";
import * as auth from "../../Auth/_redux/authRedux";
import * as assessorService from "../_redux/assessorRedux";
import UserVideoComponent from "../../../../components/OpenViduVideo";
// // import { drawRect } from "../../../../helpers/utilities";

const OPENVIDU_SERVER_URL = "https://test.tagindia.co.in";
const OPENVIDU_SERVER_SECRET = "_g0h_fektCm8VxSoUjSZJLdKqLpr1E-7n480XjPHnFc";

class TakePracticalClass extends Component {
  constructor(props) {
    super(props);
    this.videoRef = createRef();
    this.canvasRef = createRef();
    const {
      user,
      assessorService: { selectedTrainee },
      /*  SetQuestionResponse, */
    } = props;
    this.state = {
      mySessionId: user
        ? `session_practical_${user.BatchId}_${selectedTrainee}`
        : ``,
      myUserName: user ? (user.Name ? user.Name : `Assessor`) : `Assessor`,
      session: undefined,
      publisher: undefined,
      subscribers: [],
      detections: [],
      count: 0,
    };
    this.joinSession = this.joinSession.bind(this);
    this.leaveSession = this.leaveSession.bind(this);
    this.handleChangeSessionId = this.handleChangeSessionId.bind(this);
    this.handleChangeUserName = this.handleChangeUserName.bind(this);
    this.handleMainVideoStream = this.handleMainVideoStream.bind(this);
    this.onbeforeunload = this.onbeforeunload.bind(this);
    this.onOptionSelected = this.onOptionSelected.bind(this);
    this.onExamEnd = this.onExamEnd.bind(this);
  }

  componentDidMount() {
    this.joinSession();
    window.addEventListener("beforeunload", this.onbeforeunload);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onbeforeunload);
  }

  onbeforeunload(event) {
    this.leaveSession();
  }

  handleChangeSessionId(e) {
    this.setState({
      mySessionId: e.target.value,
    });
  }

  handleChangeUserName(e) {
    this.setState({
      myUserName: e.target.value,
    });
  }

  // // async runCoco() {
  // //   const net = await cocossd.load();
  // //   //console.log("Handpose model loaded.");
  // //   //  Loop and detect hands
  // //   setInterval(() => {
  // //     this.detect(net);
  // //   }, 10);
  // // }

  // // async detect(net) {
  // //   let subscribers = this.state.subscribers;
  // //   // Check data is available
  // //   if (
  // //     typeof subscribers !== "undefined" &&
  // //     subscribers !== null &&
  // //     subscribers.length > 0
  // //   ) {
  // //     // Get Video Properties
  // //     if (
  // //       typeof this.videoRef.current !== "undefined" &&
  // //       this.videoRef.current !== null &&
  // //       this.videoRef.current.readyState === 4
  // //     ) {
  // //       const video = this.videoRef.current;
  // //       const videoWidth = 320;
  // //       const videoHeight = 240;

  // //       // Set canvas height and width
  // //       this.canvasRef.current.width = videoWidth;
  // //       this.canvasRef.current.height = videoHeight;

  // //       // Make Detections
  // //       const obj = await net.detect(video);

  // //       // Draw mesh
  // //       const ctx = this.canvasRef.current.getContext("2d");
  // //       drawRect(obj, ctx);
  // //       obj.forEach((prediction) => {
  // //         const detection = prediction["class"];
  // //         var detections = this.state.detections;
  // //         if (detections.indexOf(detection) === -1) {
  // //           detections.push(detection);
  // //         }
  // //         this.setState({
  // //           detections: detections,
  // //         });
  // //       });
  // //     } else {
  // //       var subscriber = subscribers[0];
  // //       subscriber.addVideoElement(this.videoRef.current);
  // //     }
  // //   }
  // // }

  handleMainVideoStream(stream) {
    if (this.state.mainStreamManager !== stream) {
      this.setState({
        mainStreamManager: stream,
      });
    }
  }

  deleteSubscriber(streamManager) {
    let subscribers = this.state.subscribers;
    let index = subscribers.indexOf(streamManager, 0);
    if (index > -1) {
      subscribers.splice(index, 1);
      this.setState({
        subscribers: subscribers,
      });
    }
  }

  joinSession() {
    this.OV = new OpenVidu();

    this.setState(
      {
        session: this.OV.initSession(),
      },
      () => {
        var mySession = this.state.session;

        mySession.on("streamCreated", (event) => {
          var subscriber = mySession.subscribe(event.stream, undefined);
          var subscribers = this.state.subscribers;
          subscribers.push(subscriber);

          this.setState({
            subscribers: subscribers,
          });
          // // this.runCoco();
          console.log(
            "🚀 ~ file: TheoryMonitorClass.js ~ line 106 ~ ov_session.on streamCreated ~ streamId",
            event.stream.streamId
          );
        });

        mySession.on("streamDestroyed", (event) => {
          this.deleteSubscriber(event.stream.streamManager);
          console.log(
            "🚀 ~ file: TheoryMonitorClass.js ~ line 116 ~ ov_session.on streamDestroyed ~ streamId",
            event.stream.streamId
          );
        });

        this.getToken().then((token) => {
          mySession
            .connect(token, { clientData: this.state.myUserName })
            .then(() => {
              this.OV.getDevices().then((devices) => {
                var videoDevices = devices.filter(
                  (device) => device.kind === "videoinput"
                );
                var videoSource = undefined;
                if (videoDevices && videoDevices.length > 1) {
                  // // In mobile devices the default and first camera is the front one
                  videoSource = videoDevices[1].deviceId;
                } else {
                }
                const user_video = this.OV.initPublisher(undefined, {
                  audioSource: undefined, // The source of audio. If undefined default microphone
                  videoSource: videoSource, // The source of video. If undefined default webcam
                  publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
                  publishVideo: true, // Whether you want to start publishing with your video enabled or not
                  resolution: "640x480", // The resolution of your video
                  frameRate: 30, // The frame rate of your video
                  insertMode: "APPEND", // How the video is inserted in the target element 'video-container'
                  mirror: false, // Whether to mirror your local video or not
                });
                mySession.publish(user_video);
              });
            })
            .catch((error) => {
              console.log(
                "There was an error connecting to the session:",
                error.code,
                error.message
              );
            });
        });
      }
    );
  }

  leaveSession() {
    const {
      user,
      assessorService: { selectedTrainee },
    } = this.props;
    const mySession = this.state.session;
    if (mySession) {
      mySession.disconnect();
    }
    this.OV = null;
    this.setState({
      session: undefined,
      subscribers: [],
      mySessionId: user
        ? `session_practical_${user.BatchId}_${selectedTrainee}`
        : ``,
      myUserName: user ? (user.Name ? user.Name : `Assessor`) : `Assessor`,
      mainStreamManager: undefined,
      publisher: undefined,
      detections: [],
    });
  }

  getToken() {
    return this.createSession(this.state.mySessionId).then((sessionId) =>
      this.createToken(sessionId)
    );
  }

  createSession(sessionId) {
    return new Promise((resolve, reject) => {
      var data = JSON.stringify({
        customSessionId: sessionId,
        recordingMode: "ALWAYS",
        defaultOutputMode: "INDIVIDUAL",
      });
      const instance = axios.create();
      instance
        .post(OPENVIDU_SERVER_URL + "/api/sessions", data, {
          headers: {
            Authorization:
              "Basic " + btoa("OPENVIDUAPP:" + OPENVIDU_SERVER_SECRET),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("CREATE SESION", response);
          resolve(response.data.id);
        })
        .catch((response) => {
          var error = Object.assign({}, response);
          if (error.response.status === 409) {
            resolve(sessionId);
          } else {
            console.log(error);
            console.warn(
              "No connection to OpenVidu Server. This may be a certificate error at " +
                OPENVIDU_SERVER_URL
            );
            if (
              window.confirm(
                'No connection to OpenVidu Server. This may be a certificate error at "' +
                  OPENVIDU_SERVER_URL +
                  '"\n\nClick OK to navigate and accept it. ' +
                  'If no certificate warning is shown, then check that your OpenVidu Server is up and running at "' +
                  OPENVIDU_SERVER_URL +
                  '"'
              )
            ) {
              window.location.assign(
                OPENVIDU_SERVER_URL + "/accept-certificate"
              );
            }
          }
        });
    });
  }

  createToken(sessionId) {
    return new Promise((resolve, reject) => {
      var data = JSON.stringify({ session: sessionId });
      const instance = axios.create();
      instance
        .post(OPENVIDU_SERVER_URL + "/api/tokens", data, {
          headers: {
            Authorization:
              "Basic " + btoa("OPENVIDUAPP:" + OPENVIDU_SERVER_SECRET),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("TOKEN", response);
          resolve(response.data.token);
        })
        .catch((error) => reject(error));
    });
  }
  onOptionSelected(evt) {
    var value = evt.target.value;
    var id = evt.target.className;
    if (value) {
      value = parseInt(value);
    }
    id = id.replace("mr-1 ", "");
    this.props.setPracticalQuestionResponse(id, value);
    /* SetQuestionResponse(id, value); */
    // alert(id);
  }
  onExamEnd() {
    const {
      history,
      assessorService: {
        practicalQuestionResponses,
        scenarios,
        selectedTrainee,
      },
    } = this.props;
    let count = 0;
    scenarios.map((scenario, indx) => {
      scenario.PracticalQuestions.map((qcount, idx) => {
        count++;
      });
    });
    if (count !== practicalQuestionResponses.length) {
      alert(
        "Please make sure to attempt all questions before ending the exam!"
      );
    } else {
      this.props.savePracticalQuestionResponse(
        selectedTrainee,
        practicalQuestionResponses
      );
      this.props.triggerGenerateResult(selectedTrainee);
      this.props.practicalLogout();
      history.goBack();
    }
  }

  // console.log(scenarios);

  render() {
    const {
      user,
      assessorService: {
        selectedTrainee,
        scenarios,
        practicalQuestionResponses,
      },
    } = this.props;

    return (
      <Row>
        <Col md={4}>
          <div className="container">
            {this.state.session !== undefined ? (
              <div id="session">
                <div id="session-header">
                  <h4 id="session-title" className="text-center">
                    Practical Exam for trainee{" "}
                    {selectedTrainee ? selectedTrainee : ``} of batch{" "}
                    {user ? user.BatchId : ``}
                  </h4>
                </div>

                {this.state.subscribers !== undefined &&
                this.state.subscribers.length > 0 ? (
                  <div className="row mb-3">
                    <div id="main-video" className="col-md-12">
                      <UserVideoComponent
                        streamManager={this.state.subscribers[0]}
                      />
                      {/* <video
                        autoPlay={true}
                        ref={this.videoRef}
                        style={{
                          zindex: 9,
                          width: 320,
                          height: 240,
                        }}
                      /> */}
                      <canvas
                        ref={this.canvasRef}
                        style={{
                          position: "absolute",
                          marginLeft: "auto",
                          marginRight: "auto",
                          left: 0,
                          right: 0,
                          textAlign: "center",
                          zindex: 8,
                          width: 320,
                          height: 240,
                        }}
                      />
                    </div>
                    <div className="col-md-12">
                      {typeof this.state.detections !== undefined &&
                      this.state.detections.length > 0
                        ? this.state.detections.map((det, indx) => (
                            <span key={indx}>
                              {indx > 0 ? ", " : ""}
                              {det.toString()}
                            </span>
                          ))
                        : ""}
                    </div>
                  </div>
                ) : (
                  <>Waiting for Trainee to join!</>
                )}
              </div>
            ) : (
              <>Waiting for Trainee to join!</>
            )}
          </div>
        </Col>
        <Col md={8}>
          <div
            className="mb-3 border-bottom"
            style={{ height: "70vh", overflowY: "scroll" }}
          >
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Sr.#</th>
                  <th>Scenario class</th>
                  {/* <th></th> */}
                </tr>
              </thead>
              <tbody>
                {scenarios.map((scenario, indx) => (
                  <tr key={indx}>
                    <td>{indx + 1}</td>
                    <td>
                      {scenario.Scenario}
                      {/* </td>
                  <td> */}
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th width="10%">Sr.#</th>
                            <th>Question</th>
                            <th width="25%">Answer</th>
                          </tr>
                        </thead>
                        <tbody>
                          {scenario.PracticalQuestions.map(
                            (practicalQuestion, idx) => {
                              let presponse =
                                practicalQuestionResponses.findIndex(
                                  (obj) =>
                                    obj.QuestionId === practicalQuestion.Id
                                );

                              let qRes = [...practicalQuestionResponses];

                              let questionResponse = { ...qRes[presponse] };
                              //  console.log(questionResponse);

                              return (
                                <tr key={idx}>
                                  <td>{idx + 1}</td>
                                  <td>{practicalQuestion.Text}</td>
                                  <td>
                                    <div>
                                      <p className="mb-0">
                                        <input
                                          type="radio"
                                          value="0"
                                          checked={
                                            parseInt(
                                              questionResponse.Response
                                            ) === 0
                                          }
                                          id={`[${indx}].answer.[${idx}]`}
                                          name={`[${indx}].answer.[${idx}]`}
                                          className={
                                            "mr-1 " + practicalQuestion.Id
                                          }
                                          onChange={this.onOptionSelected}
                                        />
                                        <label
                                          for={`[${indx}].answer.[${idx}]`}
                                        >
                                          Poor
                                        </label>
                                      </p>
                                      <p className="mb-0">
                                        <input
                                          type="radio"
                                          value="1"
                                          id={`[${indx}].answer1.[${idx}]`}
                                          checked={
                                            parseInt(
                                              questionResponse.Response
                                            ) === 1
                                          }
                                          name={`[${indx}].answer.[${idx}]`}
                                          className={
                                            "mr-1 " + practicalQuestion.Id
                                          }
                                          onChange={this.onOptionSelected}
                                        />
                                        <label
                                          for={`[${indx}].answer1.[${idx}]`}
                                        >
                                          Good
                                        </label>
                                      </p>
                                      <p className="mb-0">
                                        <input
                                          type="radio"
                                          value="2"
                                          id={`[${indx}].answer2.[${idx}]`}
                                          checked={
                                            parseInt(
                                              questionResponse.Response
                                            ) === 2
                                          }
                                          name={`[${indx}].answer.[${idx}]`}
                                          className={
                                            "mr-1 " + practicalQuestion.Id
                                          }
                                          onChange={this.onOptionSelected}
                                        />
                                        <label
                                          for={`[${indx}].answer2.[${idx}]`}
                                        >
                                          Very Good
                                        </label>
                                      </p>
                                      <p className="mb-0">
                                        <input
                                          type="radio"
                                          value="3"
                                          id={`[${indx}].answer3.[${idx}]`}
                                          checked={
                                            parseInt(
                                              questionResponse.Response
                                            ) === 3
                                          }
                                          name={`[${indx}].answer.[${idx}]`}
                                          onChange={this.onOptionSelected}
                                          className={
                                            "mr-1 " + practicalQuestion.Id
                                          }
                                        />
                                        <label
                                          for={`[${indx}].answer3.[${idx}]`}
                                        >
                                          Excellent
                                        </label>
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Button
            variant="danger"
            /* onClick={() => {
              // // this.leaveSession();              
              history.goBack();              
            }} */
            onClick={this.onExamEnd}
          >
            End Practical Exam
          </Button>
        </Col>
      </Row>
    );
  }
}

export default connect(
  ({ auth, assessorService }) => ({
    user: auth.user,
    assessorService: assessorService,
  }),
  { ...auth.actions, ...assessorService.actions }
)(TakePracticalClass);
