import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import { connect } from "react-redux";
import * as auth from "../../Auth/_redux/authRedux";

import { DEFAULT_LOGOUT_EVENT } from "../../../../components/Defaults";
import { formatLocalDate, toAbsoluteUrl } from "../../../../helpers";

import * as traineeService from "../_redux/traineeRedux";

function VerificationStep2(props) {
  const { user, history, triggerGenerateResult, addExamEvent } = props;
  const [capturedImage, setCapturedImage] = useState("");
  const [hasImage, setHasImage] = useState(false);
  const [faceMatchStatus, setFaceMatchStatus] = useState("");

  const constraints = {
    audio: false,
    video: { width: 320, height: 240 },
  };
  const uploadPicture = async () => {
    const UPLOAD_URL = `${process.env.REACT_APP_API_URL}/api/traineeaadhaarimage`;
    try {
      await axios
        .post(UPLOAD_URL, {
          EnrollmentNo: user.EnrollmentNo,
          AadhaarImage: capturedImage
            ? capturedImage.replace("data:image/png;base64,", "")
            : "",
          ReplaceImage: true,
        })
        .then(function (response) {
          if (response.data.AutoLogoutStatus === "1") {
            addExamEvent(
              user.EnrollmentNo,
              DEFAULT_LOGOUT_EVENT,
              formatLocalDate(new Date()),
              0
            );
            localStorage.removeItem("typingteststatus");
            triggerGenerateResult(user.EnrollmentNo);
            history.push("/exam/thanks");
          }
          if (response.data.StatusCode === 200) {
            history.push("/exam");
          }
          if (response.data.StatusCode === 201) {
            setFaceMatchStatus("Face and ID do not match.");
          }
          if (response.data.StatusCode === 400) {
            setFaceMatchStatus("Invaild Capture Image");
          }
        });
    } catch (err) {}
  };
  const takePicture = () => {
    const canvas = document.querySelector("canvas");
    const context = canvas.getContext("2d");
    const video = document.querySelector("video");
    const { width, height } = constraints.video;

    canvas.width = width;
    canvas.height = height;
    context.drawImage(video, 0, 0, width, height);

    const data = canvas.toDataURL("image/png");
    setCapturedImage(data);
    setHasImage(true);
  };
  const clearPhoto = () => {
    const canvas = document.querySelector("canvas");
    const context = canvas.getContext("2d");
    const { width, height } = constraints.video;
    context.fillStyle = "#FFF";
    context.fillRect(0, 0, width, height);
    const data = canvas.toDataURL("image/png");
    setCapturedImage(data);
    //setHasImage(true);
  };
  useEffect(() => {
    const getUserMedia = (params) =>
      new Promise((successCallback, errorCallback) => {
        navigator.webkitGetUserMedia.call(
          navigator,
          params,
          successCallback,
          errorCallback
        );
      });
    getUserMedia(constraints)
      .then((stream) => {
        const video = document.querySelector("video");
        video.srcObject = stream;
        video.play();
      })
      .catch((err) => {
        console.log(err);
      });
    clearPhoto();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const styles = {
    picSize: {
      width: 320,
      height: 240,
    },
  };
  return (
    <Container>
      <Row>
        <canvas id="canvas" style={styles.picSize} hidden></canvas>
        <Col md={6} sm={12} className="mt-5">
          <Row className="text-center">
            <Col lg={12} md={12} sm={12}>
              Please capture your aadhaar card photo:
            </Col>
            <Col lg={12} md={12} sm={12}>
              <video
                autoPlay="autoplay"
                style={styles.picSize}
                className="mt-1"
              />
            </Col>
            <Col lg={12} md={12} sm={12}>
              <Button
                size="lg"
                type="button"
                onClick={takePicture}
                className="mt-1"
              >
                {!hasImage
                  ? "Take Aadhaar Card Photo"
                  : "Retake Aadhaar Card Photo"}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col id="results" md={6} sm={12} className="mt-5 text-center">
          {!hasImage ? (
            "Your captured aadhaar card photo will appear here..."
          ) : (
            <>
              <Row className="text-center">
                <Col lg={12} md={12} sm={12}>
                  Here is your aadhaar card photo:
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <img src={capturedImage} alt="your snap" />
                </Col>
                <Col lg={12} md={12} sm={12} className="mt-1">
                  <Button variant="success" size="lg" onClick={uploadPicture}>
                    Next
                  </Button>
                </Col>
              </Row>
              {faceMatchStatus !== "" ? (
                <Row className="text-center text-red">
                  <img
                    src={toAbsoluteUrl("/assests/images/error.png")}
                    alt="error"
                    style={{ width: "26px", height: "26px" }}
                  />{" "}
                  <p style={{ color: "red", fontWeight: "bold" }} class="ml-2">
                    {faceMatchStatus}
                  </p>
                </Row>
              ) : (
                ""
              )}
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default connect(
  ({ auth }) => ({
    user: auth.user,
    addExamEvent: auth.addExamEvent,
    triggerGenerateResult: auth.triggerGenerateResult,
  }),
  { ...auth.actions, ...traineeService.actions }
)(VerificationStep2);
