import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import * as auth from "../../Auth/_redux/authRedux";
import * as assessorService from "../_redux/assessorRedux";

function PracticalMonitor({
  history,
  loadPracticalScenarios,
  selectTrainee,
  assessorService: { currentQuestion, isComplete, scenarios, trainees },
  user,
}) {
  React.useEffect(() => {
    loadPracticalScenarios();
    // eslint-disable-next-line
  }, []);
  const onTakePractical = (enrollmentNo) => {
    selectTrainee(enrollmentNo);
    history.push("/assessment/practical/start");
  };
  const onTakeOjt = (enrollmentNo) => {
    selectTrainee(enrollmentNo);
    history.push("/assessment/ojt/marks");
  };
  return (
    <Row>
      <Col></Col>
      <Col lg={8} md={8} sm={12} xs={12}>
        {trainees && trainees.length > 0 ? (
          <>
            <Row className="mb-2">
              <Col lg={1} md={1} sm={1} xs={1}>
                S.No.
              </Col>
              <Col lg={2} md={2} sm={2} xs={2}>
                Enrollment No.
              </Col>
              <Col lg={5} md={5} sm={5} xs={5}>
                Name
              </Col>
              <Col lg={4} md={4} sm={4} xs={4}></Col>
            </Row>
            {trainees.map((trainee, indx) => (
              <Row key={indx} className="mb-2">
                <Col lg={1} md={1} sm={1} xs={1}>
                  {indx + 1}
                </Col>
                <Col lg={2} md={2} sm={2} xs={2}>
                  {trainee.EnrollmentNo}
                </Col>
                <Col lg={3} md={3} sm={3} xs={3}>
                  {trainee.Name}
                </Col>
                {/*   {scenarios.lenght >= 0 ? (
                  <Col lg={4} md={4} sm={4} xs={4}>
                    <Button
                      onClick={() => onTakePractical(trainee.EnrollmentNo)}
                    >
                      Take Practical
                    </Button>
                  </Col>
                ) : (
                  ""
                )} */}
                <Col lg={6} md={6} sm={6} xs={6}>
                  {scenarios.length > 0 ? (
                    <Button
                      onClick={() => onTakePractical(trainee.EnrollmentNo)}
                    >
                      Take Practical
                    </Button>
                  ) : (
                    ""
                  )}
                  {user.OjtApplicable === "true" ? (
                    <Button
                      className="ml-2"
                      onClick={() => onTakeOjt(trainee.EnrollmentNo)}
                    >
                      Give OJT Marks
                    </Button>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            ))}
          </>
        ) : (
          <>Loading...</>
        )}
      </Col>
      <Col></Col>
    </Row>
  );
}

export default connect(
  ({ auth, assessorService }) => ({
    user: auth.user,
    assessorService: assessorService,
  }),
  { ...auth.actions, ...assessorService.actions }
)(PracticalMonitor);
