import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const PRACTICAL_SCENARIO_URL = `${API_URL}/api/practicalscenarios`;
export const PRACTICAL_RESPONSE_URL = `${API_URL}/api/TraineePracticalResponse`;
export const OJT_RESPONSE_URL = `${API_URL}/api/TraineeOjtResponse`;

export const getPracticalScenarios = async () => {
  return await axios.post(PRACTICAL_SCENARIO_URL, {});
};
export const savePracticalQuestionResponse = async (
  enrollmentNo,
  responses
) => {
  return await axios.post(PRACTICAL_RESPONSE_URL, {
    EnrollmentNo: enrollmentNo,
    Answers: responses,
  });
};
export const saveOjtResponse = async (enrollmentNo, responses) => {
  return await axios.post(OJT_RESPONSE_URL, {
    EnrollmentNo: enrollmentNo,
    Inputs: responses,
  });
};
