import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
// // import axios from "axios";
import { connect } from "react-redux";
import * as auth from "../../Auth/_redux/authRedux";

function Verification(props) {
  const { /*user,*/ history } = props;
  const [capturedImage, setCapturedImage] = useState("");
  const [hasImage, setHasImage] = useState(false);
  const constraints = {
    audio: false,
    video: { width: 320, height: 240 },
  };
  const uploadPicture = async () => {
    // // const UPLOAD_URL = `${process.env.REACT_APP_API_URL}/api/traineeimage`;
    // // try {
    // //   await axios.post(UPLOAD_URL, {
    // //     EnrollmentNo: user.EnrollmentNo,
    // //     AssessmentStartImage: capturedImage
    // //       ? capturedImage.replace("data:image/png;base64,", "")
    // //       : "",
    // //   });
    // // } catch (err) {}
    history.push("/verify-step-2");
  };
  const takePicture = () => {
    const canvas = document.querySelector("canvas");
    const context = canvas.getContext("2d");
    const video = document.querySelector("video");
    const { width, height } = constraints.video;

    canvas.width = width;
    canvas.height = height;
    context.drawImage(video, 0, 0, width, height);

    const data = canvas.toDataURL("image/png");
    setCapturedImage(data);
    setHasImage(true);
  };
  const clearPhoto = () => {
    const canvas = document.querySelector("canvas");
    const context = canvas.getContext("2d");
    const { width, height } = constraints.video;
    context.fillStyle = "#FFF";
    context.fillRect(0, 0, width, height);
    const data = canvas.toDataURL("image/png");
    setCapturedImage(data);
    //setHasImage(true);
  };
  useEffect(() => {
    const getUserMedia = (params) =>
      new Promise((successCallback, errorCallback) => {
        navigator.webkitGetUserMedia.call(
          navigator,
          params,
          successCallback,
          errorCallback
        );
      });
    getUserMedia(constraints)
      .then((stream) => {
        const video = document.querySelector("video");
        video.srcObject = stream;
        video.play();
      })
      .catch((err) => {
        console.log(err);
      });
    clearPhoto();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const styles = {
    picSize: {
      width: 320,
      height: 240,
    },
  };
  return (
    <Container>
      <Row>
        <canvas id="canvas" style={styles.picSize} hidden></canvas>
        <Col md={6} sm={12} className="mt-5">
          <Row className="text-center">
            <Col lg={12} md={12} sm={12}>
              Please capture your photo:
            </Col>
            <Col lg={12} md={12} sm={12}>
              <video
                autoPlay="autoplay"
                style={styles.picSize}
                className="mt-1"
              />
            </Col>
            <Col lg={12} md={12} sm={12}>
              <Button
                size="lg"
                type="button"
                onClick={takePicture}
                className="mt-1"
              >
                {!hasImage ? "Take Photo" : "Retake Photo"}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col id="results" md={6} sm={12} className="mt-5 text-center">
          {!hasImage ? (
            "Your captured photo will appear here..."
          ) : (
            <Row className="text-center">
              <Col lg={12} md={12} sm={12}>
                Here is your photo:
              </Col>
              <Col lg={12} md={12} sm={12}>
                <img src={capturedImage} alt="your snap" />
              </Col>
              <Col lg={12} md={12} sm={12} className="mt-1">
                <Button variant="success" size="lg" onClick={uploadPicture}>
                  Next
                </Button>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default connect(
  ({ auth }) => ({
    user: auth.user,
  }),
  auth.actions
)(Verification);
