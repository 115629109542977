import axios from "axios";
import React, { Component } from "react";
import { OpenVidu } from "openvidu-browser";
import { connect } from "react-redux";
import * as auth from "../../Auth/_redux/authRedux";
// // import { getToken } from "../../../../components/OpenViduSession";
import UserVideoComponent from "../../../../components/OpenViduVideo";

const OPENVIDU_SERVER_URL = "https://test.tagindia.co.in";
const OPENVIDU_SERVER_SECRET = "_g0h_fektCm8VxSoUjSZJLdKqLpr1E-7n480XjPHnFc";

class TheoryMonitorClass extends Component {
  constructor(props) {
    super(props);
    const { user } = props;
    this.state = {
      mySessionId: user ? `session_theory_${user.BatchId}` : ``,
      myUserName: user ? (user.Name ? user.Name : `Assessor`) : `Assessor`,
      session: undefined,
      mainStreamManager: undefined,
      publisher: undefined,
      subscribers: [],
    };

    this.joinSession = this.joinSession.bind(this);
    this.leaveSession = this.leaveSession.bind(this);
    this.handleChangeSessionId = this.handleChangeSessionId.bind(this);
    this.handleChangeUserName = this.handleChangeUserName.bind(this);
    this.handleMainVideoStream = this.handleMainVideoStream.bind(this);
    this.onbeforeunload = this.onbeforeunload.bind(this);
  }

  componentDidMount() {
    this.joinSession();
    window.addEventListener("beforeunload", this.onbeforeunload);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onbeforeunload);
  }

  onbeforeunload(event) {
    this.leaveSession();
  }

  handleChangeSessionId(e) {
    this.setState({
      mySessionId: e.target.value,
    });
  }

  handleChangeUserName(e) {
    this.setState({
      myUserName: e.target.value,
    });
  }

  handleMainVideoStream(stream) {
    if (this.state.mainStreamManager !== stream) {
      this.setState({
        mainStreamManager: stream,
      });
    }
  }

  deleteSubscriber(streamManager) {
    let subscribers = this.state.subscribers;
    let index = subscribers.indexOf(streamManager, 0);
    if (index > -1) {
      subscribers.splice(index, 1);
      this.setState({
        subscribers: subscribers,
      });
    }
  }

  joinSession() {
    this.OV = new OpenVidu();

    this.setState(
      {
        session: this.OV.initSession(),
      },
      () => {
        var mySession = this.state.session;

        mySession.on("streamCreated", (event) => {
          var subscriber = mySession.subscribe(event.stream, undefined);
          var subscribers = this.state.subscribers;
          subscribers.push(subscriber);

          this.setState({
            subscribers: subscribers,
          });
          console.log(
            "🚀 ~ file: TheoryMonitorClass.js ~ line 106 ~ ov_session.on streamCreated ~ streamId",
            event.stream.streamId
          );
        });

        mySession.on("streamDestroyed", (event) => {
          this.deleteSubscriber(event.stream.streamManager);
          console.log(
            "🚀 ~ file: TheoryMonitorClass.js ~ line 116 ~ ov_session.on streamDestroyed ~ streamId",
            event.stream.streamId
          );
        });

        this.getToken().then((token) => {
          mySession
            .connect(token, { clientData: this.state.myUserName })
            .then(() => {})
            .catch((error) => {
              console.log(
                "There was an error connecting to the session:",
                error.code,
                error.message
              );
            });
        });
        // // const token = await getToken();
        // // console.log("token", token);
        // // try {
        // //     await mySession.connect(token, { clientData: this.state.myUserName });
        // // } catch (error) {
        // //   console.log(
        // //     "There was an error connecting to the session:",
        // //     error.code,
        // //     error.message
        // //   );
        // // }
      }
    );
  }

  leaveSession() {
    const { user } = this.props;
    const mySession = this.state.session;
    if (mySession) {
      mySession.disconnect();
    }
    this.OV = null;
    this.setState({
      session: undefined,
      subscribers: [],
      mySessionId: user ? `session_theory_${user.BatchId}` : ``,
      myUserName: user ? user.Name : `Assessor`,
      mainStreamManager: undefined,
      publisher: undefined,
    });
  }

  getToken() {
    return this.createSession(this.state.mySessionId).then((sessionId) =>
      this.createToken(sessionId)
    );
  }

  createSession(sessionId) {
    return new Promise((resolve, reject) => {
      var data = JSON.stringify({
        customSessionId: sessionId,
        recordingMode: "ALWAYS",
        defaultOutputMode: "INDIVIDUAL",
      });
      const instance = axios.create();
      instance
        .post(OPENVIDU_SERVER_URL + "/api/sessions", data, {
          headers: {
            Authorization:
              "Basic " + btoa("OPENVIDUAPP:" + OPENVIDU_SERVER_SECRET),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("CREATE SESION", response);
          resolve(response.data.id);
        })
        .catch((response) => {
          var error = Object.assign({}, response);
          if (error.response.status === 409) {
            resolve(sessionId);
          } else {
            console.log(error);
            console.warn(
              "No connection to OpenVidu Server. This may be a certificate error at " +
                OPENVIDU_SERVER_URL
            );
            if (
              window.confirm(
                'No connection to OpenVidu Server. This may be a certificate error at "' +
                  OPENVIDU_SERVER_URL +
                  '"\n\nClick OK to navigate and accept it. ' +
                  'If no certificate warning is shown, then check that your OpenVidu Server is up and running at "' +
                  OPENVIDU_SERVER_URL +
                  '"'
              )
            ) {
              window.location.assign(
                OPENVIDU_SERVER_URL + "/accept-certificate"
              );
            }
          }
        });
    });
  }

  createToken(sessionId) {
    return new Promise((resolve, reject) => {
      var data = JSON.stringify({ session: sessionId });
      const instance = axios.create();
      instance
        .post(OPENVIDU_SERVER_URL + "/api/tokens", data, {
          headers: {
            Authorization:
              "Basic " + btoa("OPENVIDUAPP:" + OPENVIDU_SERVER_SECRET),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("TOKEN", response);
          resolve(response.data.token);
        })
        .catch((error) => reject(error));
    });
  }

  render() {
    const { user } = this.props;
    return (
      <div className="container">
        {this.state.session !== undefined ? (
          <div id="session">
            <div id="session-header">
              <h4 id="session-title" className="text-center">
                Monitor Theory 2 Exam for batch {user ? user.BatchId : ``}
              </h4>
            </div>

            {this.state.mainStreamManager !== undefined ? (
              <div className="row mb-3">
                <div id="main-video" className="col-md-12">
                  <UserVideoComponent
                    streamManager={this.state.mainStreamManager}
                  />
                </div>
              </div>
            ) : null}
            <div id="video-container" className="row">
              {this.state.subscribers.map((sub, i) => (
                <div
                  key={i}
                  className="col-lg-3 col-md-3 col-sm-3 col-xs-6 mb-3"
                  /* onClick={() => this.handleMainVideoStream(sub)} */
                >
                  <UserVideoComponent streamManager={sub} />
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default connect(
  ({ auth }) => ({
    user: auth.user,
  }),
  auth.actions
)(TheoryMonitorClass);

// // export default TheoryMonitorClass;
